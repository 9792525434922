import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios'


const PushMessage = () => {
  /////////////////////////
  const [queryparams, setqueryparams] = useState(0);
  const [userInfo,setUserInfo] = useState([])
  const [userN,setUserN] = useState("")
  const isMounted = useRef(false);
  const [found,setFound] = useState(false)
  const [userI,setUserI] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [image,setImage] = useState('')

  // console.log(userInfo)
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // console.log(formData)

  //   try {
  //     // const response = await fetch(
  //     //   `${process.env.REACT_APP_BASEURL}/admin/notification/send?type=${queryparams}`,
  //     //   {
  //     //     method: "POST",
  //     //     body: JSON.stringify(formData),
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }
  //     // );
  //     const data1 = new FormData();
  //     data1.append('image', formData.image);
  //     data1.append('title', formData.title);
  //     data1.append('description', formData.description);

  //     console.log(formData);
  //     // return;

  //     const response = await fetch(
  //       `BASE_URL = https://mastishortss.com/video/admin/notification/send?type=${queryparams}`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify(data1),
  //       }
  //     );

  //     let data = await response.json();
  //     console.log(data);
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     toast.success("Notification sent successfully!");
  //     setFormData({
  //       title: "",
  //       description: "",
  //     });
  //   } catch (error) {
  //     console.error("Error sending notification:", error);
  //     toast.error(error.message);
  //   }
  // };


// find user 
// const getUser = async () => {
//   try {
//     setFound(true)
//     const {data} = await axios.get(`https://mastishortss.com/video/admin/notification/user?username=${userN}`)
//     // console.log(data)
//     setUserInfo(data.details)
//     setFound(false)
//   } catch (error) {
//     console.log(error.message)
//   }
// }
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // console.log([userInfo[1]._id])
      // return
      // setUserI(userInfo._id)
      const data1 = new FormData();
      data1.append('image', image);
      data1.append('title', formData.title);
      data1.append('description', formData.description);
      if(queryparams == 0) {
        // data1.append('userId', [userInfo[1]._id]);
        data1.append('userId', JSON.stringify([userInfo[1]._id])); 
      }
   

      // console.log(formData)
      // console.log(userI)
   

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL1}/admin/notification/send?type=${queryparams}`,
        {
          method: "POST",
          body: data1,
        }
      );

      let data = await response.json();
      console.log(data);
      setFormData({
        title: "",
        description: "",
        image: null, // Resetting image input if necessary
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if(data.success==1)
      toast.success("Notification sent successfully!");
    else{
      toast.error(data.message)
    }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error(error.message);
    }
  };

  const handleUserChange = (e) => {

    const user = document.getElementById("user").value;
    const replace = document.getElementById("replace");
    replace.style.display = user === "1" ? "none" : "block";
    setqueryparams(e.target.value);
  };

  const handleChange = (e) => {
    // setUserI([...userI,userInfo._id])
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // setFormData({...formData,image:e.target.file  })
  };
  /////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      try {
        setFound(true);
        setUserInfo([]);
        const { data } = await axios.get(`${process.env.REACT_APP_BASEURL1}/admin/notification/user?username=${userN}`);
        setUserInfo([...userInfo,data.details]);
        // setUserInfo(data.details);
   
      } catch (error) {
        console.log(error.message);
      } finally {
        setFound(false);
      }
    };
  
    fetchData();
    // if(userInfo.length > 0 ) {
    //   setUserI([...userI,userInfo._id])
    // }
  }, [userN]);


  return (
    <>
      <section className="content-header">
        <h1>Send Notification</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Send Notification</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
              // onSubmit={handleSubmit}
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Title</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="sel1">Select User:</label>
                    <select
                      className="form-control"
                      id="user"
                      name="userId"
                      onChange={handleUserChange}
                    >
                      <option value>select user</option>
                      <option value={0}>Paticuler User</option>
                      <option value={1}>All Users</option>
                    </select>
                    <span className="form-error1" />
                  </div>
                  <div
                    className="form-group"
                    id="replace"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="sel1">User Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      name="userId"
                      // value={formData.userId}
                      onChange={(e) => {
                        setUserN(e.target.value)
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      name="title"
                      className="form-control"
                      value={formData.title}
                      onChange={handleChange}
                      disabled={found}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Image</label>
                    <input
                    type="file"
                      name="image"
                      className="form-control"
                      value={formData.image}
                      disabled={found}
                      onChange={(e) => {
                        setImage(e.target.files[0])
                      }}
                    />
                    <div className="form-error1" />
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="description"
                      className="form-control"
                      value={formData.description}
                      onChange={handleChange}
                      disabled={found}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="button"
                      className="btn btn-success pull-right"
                      defaultValue="Submit"
                      onClick={(e) => {
                        handleSubmit(e)
                      }} 
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default PushMessage;
