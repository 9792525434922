import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddTargetWithAA = () => {
  const navigate = useNavigate();
  const [agencyAdmins, setAgencyAdmins] = useState([]);
  const [createdFor, setCreatedFor] = useState('');
  const [amount, setAmount] = useState('');
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  let bddata = JSON.parse(sessionStorage.getItem('bd'));
  let bd = bddata.uniqueId;

  useEffect(() => {
    // Fetch the agency admins when the component mounts
    setLoading(true); // Set loading to true when fetching starts
    fetch(
      `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/getJuniorList?uniqueId=${bd}&type=0`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success == '1') {
          setAgencyAdmins(data.details);
        } else {
          toast.error(data.message);
        }
        setLoading(false); // Set loading to false when fetch is complete
      })
      .catch((error) => {
        console.error('Error fetching agency admins:', error);
        toast.error('Failed to fetch agency admins');
        setLoading(false); // Set loading to false in case of error
      });
  }, [bd]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let text = 'This field cannot be empty';

    if (!createdFor) {
      setError1(text);
    } else if (!amount) {
      setError2(text);
    } else {
      let formData = {
        createdFor,
        createdBy: bd,
        amount,
      };

      fetch(
        `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/createTargetWithdrawal`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.success == '1') {
            toast.success(res.message);
            setTimeout(() => {
              navigate('/bd/getTargetWith');
            }, 1000);
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error(error.message);
        });
    }
  };

  const loadingStyle = {
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    fontSize: '16px',
    color: '#007bff',
    marginBottom: '20px',
  };

  const errorStyle = {
    color: 'red',
    marginTop: '5px',
  };

  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: '20px' }}>Add Target Withdrawal</h1>
      </section>
      <section className="content" style={{ padding: '0 15px' }}>
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Agency admin</label>
                    {loading ? (
                      <div style={loadingStyle}>Loading...</div> // Loading indicator
                    ) : (
                      <select
                        className="form-control"
                        name="createdFor"
                        style={{ width: '100%' }}
                        value={createdFor}
                        onChange={(e) => {
                          setCreatedFor(e.target.value);
                          setError1('');
                        }}
                      >
                        <option value="">Select Agency admin</option>
                        {agencyAdmins.map((admin) => (
                          <option key={admin.uniqueId} value={admin.uniqueId}>
                            {admin.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {error1 && (
                      <div style={errorStyle}>{error1}</div>
                    )}
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      placeholder=""
                      style={{ width: '100%' }}
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setError2('');
                      }}
                    />
                    {error2 && (
                      <div style={errorStyle}>{error2}</div>
                    )}
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTargetWithAA;
