import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";

const RemovedRanking = () => {
  const coinData = sessionStorage.getItem("data");
  const coinDetails = JSON.parse(coinData);
  const [coinDataValue, setCoinDataValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [u,setU] = useState(null)
  const [checkBal, setCheckBal] = useState();
  // console.log(coinDetails);
  // fetch user details
  useEffect(() => {
    if (!sessionStorage.getItem("data")) {
      window.location.href = "/";
    }
  }, []);
  const checkuser = () => {
    var userId = $("input[name='userId']").val();

    $("#form-error1").remove();
    $.ajax({
      url: `${process.env.REACT_APP_BASEURL}/coin/recharge/sendCoinfetchuser`,
      type: "get",
      dataType: "json",
      data: {
        userId,
      },
      success: function (res) {
        if (res.success === 1) {
          if (res.details.username != "") {
            var div = `
                  <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;" >
                    <img src=${process.env.REACT_APP_BASEURL}${res.details.picture} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                    <span style='margin-left: 15px; '>${res.details.username} <br /> ${res.details.name}</span>
                  </div>
                `;
          }
          setU(res.details._id);

          $(".form-error4").html("");
          $("#user").append(div);
          // var container = document.getElementById("user");
          // var secondDiv = container.querySelector("div:nth-child(2)");
        } else {
          $(".form-error4").html("no user found");
        }
      },
    });
  };

  const subbbb = async(e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL3}/admin/familyImage/createRemovedRanks?id=${u}`
      );
      if(data.success==1){
        toast.success(data.message)
       }
       else{
        toast.error(data.message)
       }
       getDataOfCoinPanel();

      //  setU(null)
    } catch (error) {
      toast.error(error.message)
    }
  };
  const getDataOfCoinPanel = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/familyImage/getRemovedRanks`
      );
      setCheckBal(data.details);
      // console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const deleteRemovedRanks = async (id) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASEURL3}/admin/familyImage/deleteRemovedRanks?id=${id}`
      );
     if(data.success==1){
      toast.success(data.message)
     }
     else{
      toast.error(data.message)
     }
     setU(null)
     getDataOfCoinPanel();

      // console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(coinDataDetails);
  useEffect(() => {
    getDataOfCoinPanel();
  }, [u]);

  // console.log(coinDataValue);
  return (
    <>
      <section className="content-header">
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1>Remove User Rank</h1>
          </div>
          </div>
        

      
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Send</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" id="user">
                    <label>Username*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="userId"
                      placeholder="username"
                      onChange={() => {
                        $(".form-error4").html("");
                        checkuser();
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
              
                 
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    {loading ? (
                      <p>Removing...</p>
                    ) : (
                      <input
                        type="submit"
                        className="btn btn-success pull-right"
                        name="submit"
                        defaultValue="Submit"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              {/* /.box */}
              <div className="box">
                {/* /.box-header */}
                <div className="box-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>UserName</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(checkBal) &&
                        checkBal?.map((item, index) => {
                          let i = index + 1; // Start index from 1
                          return (
                            <tr>
                              <td>{i}</td>
                              <td>{item?.userId?.username}</td>
                              <td>
                                <button
                                className="btn btn-danger"
                                onClick={() => {
                                  const confirmDelete = window.confirm(
                                    "Are you sure you want to Delete"
                                  );
                                  if(confirmDelete)  
                                  deleteRemovedRanks(item?._id);
                                  }}
                                  >Delete</button>
                              </td>
                             
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* PHP session message removed */}
                </div>
                {/* /.box-body */}
              </div>
              {/* /.box */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </section>
    </>
  );
};

export default RemovedRanking;
