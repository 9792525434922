import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const VideoModal = ({ isOpen, videoUrl, onClose }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    onClose();
  };

  return (
    <div
      style={{
        display: isOpen ? "block" : "none",
        position: "fixed",
        zIndex: 1000,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
      onClick={onClose} // Close modal when clicking outside the content
    >
      <div
        style={{
          backgroundColor: "#fefefe",
          margin: "10% auto",
          padding: "10px",
          border: "1px solid #888",
          width: "320px",
          position: "relative",
          borderRadius: "8px",
          pointerEvents: "auto", // Allow clicks on the modal content
        }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        
        <video controls style={{ width: "100%", height: "100%" }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

const Video = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [videos, setVideos] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [sortBy, setSortBy] = useState("createdAt");
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [currentVideoUrl, setCurrentVideoUrl] = useState(""); // Current video URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL4}/admin/video?page=${currentPage}&limit=${entriesPerPage}&type=${sortBy}`
        );
        setVideos(response.data.details);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl, currentPage, entriesPerPage, sortBy]);

  const handleDelete = async (videoId) => {
    try {
      await axios.delete(`${baseUrl}admin/video/${videoId}`);
      setVideos(videos.filter((video) => video._id !== videoId));
    } catch (error) {
      console.log("Error deleting video:", error);
    }
  };

  const handleView = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl(""); // Clear video URL on close
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section className="content-header">
        <h1>Video List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Video List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}>
                  {/* Entries per page and sort by selectors */}
                  {/* ... */}
                  <div className="entries-per-page col-md-6">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="sortBy">Sort by:</label>
                    <select
                      id="sortBy"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}  // Update sorting option
                    >
                      <option value="createdAt">Date Created</option>
                      <option value="viewCount">View Count</option>
                    </select>
                  </div>
                </div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Thumbnail</th>
                      <th>Views</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <>Loading...</>
                  ) : (
                    <tbody>
                      {videos.map((item, index) => {
                        const i = index + 1 + (currentPage - 1) * entriesPerPage;
                        return (
                          <tr key={item._id}>
                            <td>{i}</td>
                            <td>{item?.username}</td>
                            <td>
                              <img
                                src={`${item?.thumbnail}`}
                                alt="Thumbnail"
                                style={{ height: "50px", width: "50px", borderRadius: "100%" }}
                              />
                            </td>
                  
                            <td className="d-flex border justify-content-center">
                              {item?.viewCount}
                            </td>
                            <td>
                              <ul className="admin-action btn btn-default">
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => handleView(item.video)} // Pass the video URL
                                      >
                                        View
                                      </Link>
                                    </li>
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {/* Pagination controls */}
                <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li className="page-item disabled">
                        <span className="page-link">
                          Page {currentPage} of {totalPages}
                        </span>
                      </li>
                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* ... */}
              </div>
            </div>
          </div>
          </div>
      </section>

      {/* Video Modal */}
      <VideoModal isOpen={isModalOpen} videoUrl={currentVideoUrl} onClose={closeModal} />
    </>
  );
};

export default Video;
