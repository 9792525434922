import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

const GetTargetWithdrawlAA = () => {
    const baseUrl = process.env.REACT_APP_BASEURL1;

    const [hostRecord, setHostRecord] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [showRules, setShowRules] = useState(false); // New state for toggling rules

    let bddata = JSON.parse(sessionStorage.getItem("agencyadmin"));
    let bd = bddata.uniqueId;

    useEffect(() => {
        fetchAdminHostRecord();
    }, [entriesPerPage]);

    useEffect(() => {
        fetchAdminHostRecord();
    }, [page]);

    const renderSerialNumber = (index) => {
        return (page - 1) * entriesPerPage + index + 1;
    };

    const fetchAdminHostRecord = async () => {
        try {
            setHostRecord([]);
            const data = await axios.get(
                `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/getForCreatedBy?page=${page}&limit=${entriesPerPage}&uniqueId=${bd}`
            );

            if (data) {
                setHostRecord(data.data.details);
                setCount(data.data.total);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const agencyadminlistsearch = async (query = "") => {
        try {
            setHostRecord([]);
            const response = await axios.get(
                `${process.env.REACT_APP_BASEURL1}/admin/bannedUserssearch`,
                {
                    params: {
                        search: query,
                    },
                }
            );
            setHostRecord(response.data.details);
            setCount(response.data.total);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = useCallback(
        _.debounce((value) => {
            setSearchQuery(value);
            if (value !== "") agencyadminlistsearch(value);
            else {
                fetchAdminHostRecord();
            }
        }, 1000),
        []
    );

    return (
        <>
            <section className="content-header">
                <h1>Target Withdrawal</h1>
            </section>
            <section className="content">
                <div className="row">
                    <div className="actions-container">
                        <span
                            className="rules-text btn btn-info"
                            onClick={() => setShowRules(!showRules)} // Toggle rules visibility
                        >
                            {showRules ? "Hide Rules" : "Rules"}
                        </span>
                        <Link
                            to={`/agencyadmin/addTargetWith`}
                            className="btn btn-success btn-xs"
                        >
                            Add Target Withdrawal
                        </Link>
                    </div>

                    {showRules && (
                        <div className="rules-info">
                            <ol>
                            <li>
                                Target for Withdrawal will be created for current month.
                            </li>
                            <li>
                                You can only edit before 20th date of every month.
                            </li>
                            <li>
                                You cannot delete once created. If you want to delete, then contact admin.
                            </li>
                            </ol>
                        </div>
                    )}

                    <div className="col-xs-12">
                        <div className="box">
                            <div className="box-body">
                                <div className="row " style={{ padding: "16px 0px" }}>
                                    <div className="entries-per-page col-md-6 ">
                                        <label htmlFor="entries">Entries per page:</label>
                                        <select
                                            id="entries"
                                            value={entriesPerPage}
                                            onChange={(e) =>
                                                setEntriesPerPage(parseInt(e.target.value))
                                            }
                                        >
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                    <div className="col-xs-4"></div>
                                    {/* <div className="form-group col-xs-4">
                                        <label htmlFor="search">Search:</label>
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            onChange={(e) => handleSearchChange(e.target.value)}
                                        />
                                    </div> */}
                                </div>

                                <table
                                    id="example1"
                                    className="table table-bordered table-striped"
                                >
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>CreatedFor</th>
                                            <th>Name</th>
                                            <th>Month</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="ts">
                                        {hostRecord.length === 0
                                            ? "No data..."
                                            : hostRecord?.map((host, i) => (
                                                <tr key={host._id}>
                                                    <td>{renderSerialNumber(i)}</td>
                                                    <td>{host.createdFor}</td>
                                                    <td>{host.name}</td>
                                                    <td>{host.month}</td>
                                                    <td>{host.amount}</td>
                                                    <td>
                                                        <ul className="admin-action btn btn-default">
                                                            <li className="dropdown">
                                                                <a
                                                                    className="dropdown-toggle"
                                                                    style={{ color: "black" }}
                                                                    data-toggle="dropdown"
                                                                    href="#"
                                                                    aria-expanded="false"
                                                                >
                                                                    Action <span className="caret" />
                                                                </a>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    <li role="presentation">
                                                                        <Link
                                                                            role="menuitem"
                                                                            tabIndex={-1}
                                                                            to={`/agencyadmin/editTargetWith?id=${host._id}`}
                                                                        >
                                                                            Edit
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/* Pagination links */}
                                <div>
                                    <ul className="pagination">
                                        <div>Total Entries : {count}</div>
                                        <li>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setPage((prev) => Math.max(prev - 1, 1));
                                                }}
                                            >
                                                Previous
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    if (Math.ceil(count / entriesPerPage) > page)
                                                        setPage((prev) => prev + 1);
                                                }}
                                            >
                                                Next
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CSS for styling */}
            <style jsx>{`
        .actions-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
        }

        .rules-text {
          cursor: pointer;
          margin-right: 10px;
        }

        .rules-info {
          background-color: #f8f9fa;
          border: 1px solid #dee2e6;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 16px;
        }

        .btn-info {
          color: #fff;
          background-color: #17a2b8;
          border-color: #17a2b8;
        }

        .btn-success {
          color: #fff;
          background-color: #28a745;
          border-color: #28a745;
        }

        .btn-xs {
          font-size: 12px;
          padding: 4px 8px;
        }
      `}</style>
        </>
    );
};

export default GetTargetWithdrawlAA;
