import React, { useContext, useEffect, useState } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import { global } from "../../Context/Context";
import { value } from "../../config/data";
import axios from "axios";

const FamilyMembersWithdrawal = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [lead,setLead] = useState('');
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)
  const [loading,setLoading] = useState(false)
  let [searchParams] = useSearchParams();
  const startData = searchParams.get('id');
  let splitData = startData.split("?")
  // const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
  //   const convertedAmount = total.totalINR*value.familyComm;
  //   return acc + convertedAmount;
  // }, 0);

  const currentDate = new Date();

  // Calculate the date two days before the current date
  const startDateObj = new Date(currentDate);
  startDateObj.setDate(currentDate.getDate() - 7);
  
  // Calculate the date one day before the current date
  const endDateObj = new Date(currentDate);
  endDateObj.setDate(currentDate.getDate());
  
  // Format the start date to YYYY-MM-DD
  const startYear = startDateObj.getFullYear();
  const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDateObj.getDate()).padStart(2, '0');
  const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
  
  // Format the end date to YYYY-MM-DD
  const endYear = endDateObj.getFullYear();
  const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDateObj.getDate()).padStart(2, '0');
  const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
  

  // if (startDate === null) {
  //   setStartDate(startFormattedDate);
  // }
  if (endDate === null) {
    setEndDate(endFormattedDate);
  }

  // console.log(startDate)
  let currentDate1 = new Date();
  let currentDay1 = currentDate1.getDay();
  let prevMonday = new Date(currentDate1);
  prevMonday.setDate(currentDate.getDate() - ((currentDay1 + 6) % 7));
  
let lastMonday = startDate !== null ? startDate : prevMonday.toISOString().split("T")[0];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { timeZone: 'Asia/Kolkata' };
  return date.toLocaleString('en-US', options);
};

const getData = sessionStorage.getItem("agency");
const getDataParse = JSON.parse(getData)
  //Get Bds lists 
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL2}/admin/withdrawal/familymemberwithdrawal?start=${lastMonday}&end=${endDate}&id=${splitData[0]}`)
   
    // console.log(data)
    if(data.success == '1') {
      console.log(data.details)
      setWithdrawalLists(data.memDet)
      setLead(data.leaderdetails)
      setLoading(false)
    }
 } catch (error) {
    console.log(error.message)
 }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, [lastMonday,startDate,endDate]);


  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  // const currentUsers = user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers =
  //   searchData.length > 0
  //     ? searchData.slice(indexOfFirstUser, indexOfLastUser)
  //     : user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers = widthdrawalList.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  // const totalPages = Math.ceil(user.length / entriesPerPage);
  const totalPages = Math.ceil(
    user?.length / entriesPerPage
  );




  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Family Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  {/* <div className="row " style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-6 ">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) =>
                          setEntriesPerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </div>

                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                         Start Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setStartDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          End Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setEndDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </form>
              <div className="box-body">
                {/* <!-- Content here --> */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    <th>Sr.</th>
                    <th>Date</th>
                    <th>Username</th>
                      <th>Family name</th>
                      <th>Withdrawl coins</th>
                      <th>USD Amount</th>
                      <th>Target USD Amount</th>
                      <th>Total</th>
                      <th>INR</th>
                      <th>Family Commission(5%)</th>
                        </tr>
                  </thead>
                  <tbody id="ts">

                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" style={{width:"100%",margin:"0 auto"}}>Loading...</h6> </td> : <>
                    
                    <h5>Leader</h5>
                       <tr key={1}>
                       <td>1</td>
                       <td>{lead?.createdAt ? formatDate(lead.createdAt) : 'Invalid date'}</td>


                        <td>{lead?.username}</td>
                        <td>{lead?.name}</td>
                        <td>{lead?.totalCoins}</td>
                        <td>{(lead?.totalCoins)/value.USD}</td>
                        <td>{lead?.bonus?.targetCommission}</td>
                        <td><span style={{fontWeight:"bold"}}> </span>{((+(lead?.totalCoins/value.USD).toFixed(2) + +(lead?.bonus?.targetCommission)))}</td>
                        <td><span style={{fontWeight:"bold"}}>Rs </span>{(((+(lead?.totalCoins/value.USD).toFixed(2) + +(lead?.bonus?.targetCommission))*value.USDINR).toFixed(2))}</td>
                        <td><span style={{fontWeight:"bold"}}>Rs </span>{((((+(lead?.totalCoins/value.USD).toFixed(2) + +(lead?.bonus?.targetCommission))*value.USDINR).toFixed(2)*value.familyComm).toFixed(2))}</td>
                        
                      </tr>
                      <h5>Members</h5>
                      {
                    Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                      
                        let i = indexOfFirstUser + index + 1;
                        let usd = (+(item?.totalCoins/value.USD).toFixed(2) + +(item?.bonus?.targetCommission))
                        let inr = ((+(item?.totalCoins/value.USD).toFixed(2) + +(item?.bonus?.targetCommission))*value.USDINR).toFixed(2);
                        let commission = (inr*value.familyComm).toFixed(2);
                        return (
                          <tr key={index}>
                           <td>{i}</td>
                           <td>{item?.createdAt ? formatDate(item.createdAt) : 'Invalid date'}</td>


                            <td>{item?.username}</td>
                            <td>{item?.name}</td>
                            <td>{item?.totalCoins}</td>
                            <td>{(item?.totalCoins)/value.USD}</td>
                            <td>{item?.bonus.targetCommission}</td>
                            <td><span style={{fontWeight:"bold"}}> </span>{(usd)}</td>
                            <td><span style={{fontWeight:"bold"}}>Rs </span>{(inr)}</td>
                            <td><span style={{fontWeight:"bold"}}>Rs </span>{(commission)}</td>
                            
                          </tr>
                        );
                      })
                    }
                      </>
                      }
                    
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                   
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries:{" "}
                      {/* <b>
                        {searchData?.length > 0
                          ? searchData?.length
                          : user?.length}
                      </b> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default FamilyMembersWithdrawal;
