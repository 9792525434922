import React, { useContext, useEffect, useState } from "react";
import "../Css/Login.css";

import mastiLive_logo from "../../assets/img/mastiLive_logo.png";
import axios from "axios";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
const AgencyAdminLogin = () => {
  const [res, setres] = useState("");
  const baseUrl = process.env.REACT_APP_BASEURL1;

  const [formData, setFormData] = useState({
    uniqueId: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/agencyadmin/login`,
        formData
      );

      setres(response.data);

      toast.success(response.data.message);

      if (response.data.success == 1) {
        sessionStorage.setItem(
          "agencyadmin",
          JSON.stringify(response.data.agency)
        );

        setTimeout(() => {
          window.location.href = "/agencyadmin/dashboard";
        }, 1000);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <a href>
            <img style={{ width: "180px" }} src={mastiLive_logo} />
          </a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">
            Sign in to Enter your Agency Admin panel
          </p>
          <div className="form-error"></div>
          <form action="" method="" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
              <input
                type="text"
                className="form-control"
                placeholder="UniqueId"
                name="uniqueId"
                value={formData.uniqueId}
                onChange={handleChange}
              />
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="row">
              <div className="col-xs-8">
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
              </div>

              <div className="col-xs-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                  // onClick={login()}
                >
                  Sign In
                </button>
              </div>
              <div className="col-xs-8">
              <Link to="/agencyadmin/otpsent">Forget Password</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AgencyAdminLogin;
