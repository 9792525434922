import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import axios from "axios";

const EditUserLevel = () => {
  const { fetchlevel, getUserLevel, userLevel } = useContext(global);
  const [levelId, setLevelId] = useState("");
  const [image, setImage] = useState(sessionStorage.getItem("image"));
  const [level, setLevel] = useState("");
  const filterData = userLevel.filter((d) => d._id === levelId);

  const formDataToSend = new FormData();
  const navigate = useNavigate();
  const subbbb = async (e) => {
    e.preventDefault();
    formDataToSend.append("id", levelId);
    formDataToSend.append("level", level);
    formDataToSend.append("image", image);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/admin/entryeffects/updateentryeffectlevel`,
        formDataToSend,

        { params: { id: levelId } }
      );

      if (response) {
        toast.success("level edit  successfully!");
      } else {
        toast.error("Failed to add gift. Please try again later.");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to edit . Please try again later.");
    }
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  //   $.ajax({
  //   url: `${process.env.REACT_APP_BASEURL}/admin/userlevel/update`,
  //   type: "patch",
  //   dataType: "json",
  //   data: formData,
  //   processData: false, // Add this line
  //   contentType: false, // Add this line
  //   success: function (res) {
  //     if (res.success === 1) {
  //       toast.success(res.message);
  //       setTimeout(() => {
  //         navigate("/admin/userwallpaper");
  //       }, 1000);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   },
  // });

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get("id");
    setLevelId(userId);
    formDataToSend.append("id", userId);
    getUserLevel();
    $("#form")[0].reset(); // Reset the form using jQuery
    fetchlevel(userId);
    // console.log(level);
  }, []);
  // useEffect(() => {
  //   if (filterData.length > 0) {
  //     setLevel(filterData[0]?.level);
  //     console.log(filterData);
  //   }
  // }, [filterData]);

  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: "20px" }}>Edit Level</h1>
        <ol className="breadcrumb" style={{ marginBottom: "20px" }}>
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageLevel">Manage Level</Link>
          </li>
          <li className="active">Edit Level</li>
        </ol>
      </section>
      <section className="content" style={{ padding: "0 15px" }}>
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              id="form"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input type="hidden" name="id" value={filterData[0]?._id} />
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Level*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="level"
                      placeholder="level eg.(1 , 2 , 3...)"
                      style={{ width: "100%" }}
                      value={level}
                      onChange={(e) => setLevel(e.target.value)}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Icon*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="userlevel"
                      style={{ width: "100%" }}
                      onChange={handleImageChange} // Call handleImageChange when a new image is selected
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />
                    <img src={filterData[0]?.image} alt="" height={"30px"} />
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <button
                      type="reset"
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditUserLevel;
