import React, { useContext, useEffect, useState } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import { global } from "../../Context/Context";
import {value} from '../../config/data'
import axios from "axios";
import { toast } from "react-toastify";

const AgencyAdminWithdrawalLists = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [startDate, setStartDate] = useState(() => localStorage.getItem('startDate') || null);
  const [endDate, setEndDate] = useState(() => localStorage.getItem('endDate') || null);
  useEffect(() => {
    if (startDate) {
      localStorage.setItem('startDate', startDate);
    }
    if (endDate) {
      localStorage.setItem('endDate', endDate);
    }
  }, [startDate, endDate]);
  const [loading,setLoading] = useState(false)
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  let [searchParams] = useSearchParams();


  const totolSumOfCommission = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = parseFloat(((((total?.totalDollor)*value.USDINR)*value.agencyComm).toFixed(2)))
    return acc + convertedAmount;
  }, 0); 
  const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = parseFloat(((total?.totalDollor)*value.USDINR).toFixed(2))
    return acc + convertedAmount;
  }, 0); 
  
  // const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
  //   const convertedAmount = (total.totalINR );
  //   return acc + convertedAmount;
  // }, 0);
  // const totolSumOfCommission = widthdrawalList.reduce((acc, total) => {
  //   const convertedAmount = (total.totalINR )*value.agencyComm;
  //   return acc + convertedAmount;
  // }, 0);



  

  const currentDate = new Date();

  // Calculate the date two days before the current date
  const startDateObj = new Date(currentDate);
  startDateObj.setDate(currentDate.getDate() - 7);
  
  // Calculate the date one day before the current date
  const endDateObj = new Date(currentDate);
  endDateObj.setDate(currentDate.getDate());
  
  // Format the start date to YYYY-MM-DD
  const startYear = startDateObj.getFullYear();
  const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDateObj.getDate()).padStart(2, '0');
  const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
  
  // Format the end date to YYYY-MM-DD
  const endYear = endDateObj.getFullYear();
  const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDateObj.getDate()).padStart(2, '0');
  const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
  

  // if (startDate === null) {
  //   setStartDate(startFormattedDate);
  // }
  if (endDate === null) {
    setEndDate(endFormattedDate);
  } 

  // console.log(startDate)
  let currentDate1 = new Date();
  let currentDay1 = currentDate1.getDay();
  let prevMonday = new Date(currentDate1);
  prevMonday.setDate(currentDate.getDate() - ((currentDay1 + 6) % 7));
  
let lastMonday = startDate !== null ? startDate : prevMonday.toISOString().split("T")[0];


const getData = sessionStorage.getItem("agencyadmin");
const getDataParse = JSON.parse(getData)
// console.log(getDataParse)

  //Get Bds lists 
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/withdrawal/WithAg?start=${lastMonday}&end=${endDate}&agencyadmin=${getDataParse.uniqueId}`)
   
    // console.log(data)
    if(data.success =='1') {
      // console.log(data.details)
    setLoading(false)
      setWithdrawalLists(data.details)
      toast.success(data.message)
      
    }
 } catch (error) {
    console.log(error.message)
 }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, [lastMonday,endDate,startDate]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  // const totalPages = Math.ceil(user.length / entriesPerPage);
  const totalPages = Math.ceil(
    user?.length / entriesPerPage
  );



  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Bd Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>
                   

                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                         Start Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            value={startDate}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setStartDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          End Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            value={endDate}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setEndDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="box-body">

    <div style={{float:"right",fontWeight:"bolder",marginRight:"5rem" }}>
                  <span>Total : {(totolSumOfWithdrawal + totolSumOfCommission).toFixed(2)} Rs /-</span>
                </div>
    <div style={{float:"right",fontWeight:"bolder",marginRight:"5rem" }}>
                  <span>Agency : {(totolSumOfCommission).toFixed(2)} Rs /-</span>
                </div>
    <div style={{float:"right",fontWeight:"bolder",marginRight:"5rem" }}>
                  <span>Host : {(totolSumOfWithdrawal).toFixed(2)} Rs /-</span>
                </div>
              <div style={{border:"1px solid white",display:"flex",gap:"2rem"}}>
                  <div className="" >
                    <span style={{fontWeight:"bold"}}>From :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{lastMonday}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <span style={{fontWeight:"bold"}}>To :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{endDate}</span>
                  </div>
                </div>
                {/* <!-- Content here --> */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    {/* <th>Select</th> */}
                    <th>Sr.</th>
                      <th>AgencyAdmin Id</th>
                      <th>AgencyAdmin Name</th>
                      <th>W. Coins</th>
                      <th>INR</th>
                      <th>Agency Admin Commission</th>
                      <th>Agency Commission</th>  
                      <th>Action</th>
                   
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" style={{width:"100%",margin:"0 auto"}}>Loading...</h6> </td> : Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr key={index}>
                          {/* <td>
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(item.bd._id)}
                              onChange={() => handleCheckboxChange(item.bd._id)}
                            />
                          </td> */}
                          
                          <td>{i}</td>
                       
                          <td>{item?.agency}</td>
                          <td>{item?.agencyName}</td>
                          {/* <td>{bd}</td> */}
                          <td>{item?.totalAmount}</td>
                          <td>{((item?.totalDollor)*value.USDINR).toFixed(2)}</td>
                          <td>{0}</td>
                          <td>{(((item?.totalDollor)*value.USDINR)*value.agencyComm).toFixed(2)}</td>
                      
                          <td>
                            <ul
                              className="admin-action btn btn-default"
                              style={{
                                backgroundColor: "#f4f4f4",
                                color: "#fff !important",
                              }}
                            >
                              {/* ... (your existing dropdown menu items) */}
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  style={{ color: "black" }}
                                  data-toggle="dropdown"
                                  href="#"
                                  aria-expanded="false"
                                >
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li role="presentation">
                                    <Link
                                      role="menuitem"
                                      tabIndex={-1}
                                      to={`/agencyadmin/agencywithdrawalhosts?agency=${item?.agency}&start=${lastMonday}&end=${endDate}&agencyadmin=${getDataParse.uniqueId}`}
                                    >
                                      View
                                    </Link>
                                  </li>
                                
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
// Define active style object
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default AgencyAdminWithdrawalLists;
