import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import { useNavigate } from "react-router-dom";

const CoinPanelProfile = () => {
  const navigate = useNavigate();
  const BaseUrl = process.env.REACT_APP_BASEURL;
  let storedName = sessionStorage.getItem("data");
  let details = JSON.parse(storedName);
  let agencyDetail = sessionStorage.getItem("agency");
  let AgencySessiomDetails = JSON.parse(agencyDetail);
  let coinDetail = sessionStorage.getItem("coin");
  let coinDetails = JSON.parse(coinDetail);
  let x = window.location.pathname.toString();
  let ans = x[0] + x[1] + x[2] + x[3] + x[4] + x[5];
  let agency = ans + x[6];

  const [credentials, setCredentails] = useState({});
  const changePassword = async () => {
    try {
      // setCredentails({
      //   currentpassword: document.getElementById("currp").value,
      //   newpassword: document.getElementById("newp").value,
      //   confirmpassword: document.getElementById("confirmp").value,
      // });
      let data = await fetch(
        `${BaseUrl}/coinPanel/passwordReset/${coinDetails._id}`,
        {
          // method: "POST",
          method: "PATCH",
          body: JSON.stringify({
            //   currentpassword: document.getElementById("currp").value,
            //   newpassword: document.getElementById("newp").value,
            //   confirmpassword: document.getElementById("confirmp").value,
            oldPassword: document.getElementById("currp").value,
            newPassword: document.getElementById("newp").value,
            confirmPassword: document.getElementById("confirmp").value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let res = await data.json();
      // console.log(res)
      if (res.success === 1) {
        await toast.success(res.message);
        // sessionStorage.setIte/m("data", JSON.stringify(res.details));
        sessionStorage.setItem("coin", JSON.stringify(res.updatePassword));
        await navigate("/coin/dashboard");
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [readOnly, setReadOnly] = useState(true); // State variable to track readonly status

  const toggleReadOnly = () => {
    setReadOnly(!readOnly); // Toggle readonly status
  };

  const subbbb = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    console.log(formData);
    $.ajax({
      url: `${process.env.REACT_APP_BASEURL}/coinPanel/edit/${coinDetails._id}`,
      type: "patch",
      dataType: "json",
      data: formData,
      contentType: false,
      processData: false,
      success: function (res) {
        if (res.success === 1) {
          toast.success(res.message);
          sessionStorage.setItem("coin", JSON.stringify(res.updateProfile));
          toggleReadOnly();
        } else {
          toast.error("Unbl to update");
        }
      },
    });
  };

  useEffect(() => {}, [coinDetails]);

  return (
    <>
      <section className="content-header">
        <h1>User Profile</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">
              <i className="fa fa-dashboard" /> Home
            </a>
          </li>
          <li className="active">User profile</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-3">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <img
                  className="profile-user-img img-responsive img-circle"
                  style={{ height: "150px", width: "150px" }}
                  src={
                    agency === "/agency"
                      ? AgencySessiomDetails?.image
                      : ans === "/coin/"
                      ? coinDetails?.image
                      : details?.image
                  }
                  alt="User profile picture"
                />
                <h3 className="profile-username text-center">
                  {coinDetails?.name}
                </h3>
                {/* <p className="text-muted text-center">{details.bio}</p> */}
              </div>
            </div>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">About Me</h3>
              </div>
              <div className="box-body">
                <strong>
                  <i className="fa fa-phone margin-r-5" /> Phone Number
                </strong>
                <p className="text-muted">{coinDetails?.number}</p>
                <hr />
                <strong>
                  <i className="fa fa-envelope margin-r-5" /> Email
                </strong>
                <p className="text-muted">{coinDetails?.email}</p>
                <hr />
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#settings" data-toggle="tab">
                    Settings
                  </a>
                </li>
                <li>
                  <a href="#changePassword" data-toggle="tab">
                    Change Password
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="active tab-pane" id="settings">
                  {/* form  */}
                  <form
                    className="form-horizontal"
                    method="post"
                    onSubmit={subbbb}
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Name
                      </label>
                      <input
                        type="hidden"
                        defaultValue={coinDetails?._id}
                        name="id"
                      />
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="name"
                          defaultValue={coinDetails?.name}
                          className="form-control"
                          id="inputName"
                          placeholder="Master Panel"
                          // readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputEmail"
                        className="col-sm-2 control-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          defaultValue={coinDetails?.email}
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                          // readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputPhone"
                        className="col-sm-2 control-label"
                      >
                        Phone
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="number"
                          defaultValue={coinDetails?.number}
                          className="form-control"
                          id="inputPhone"
                          placeholder="Phone"
                          // readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label
                        htmlFor="inputPhone"
                        className="col-sm-2 control-label"
                      >
                        Bio
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="bio"
                          defaultValue={details.bio}
                          className="form-control"
                          id="inputPhone"
                          placeholder="Phone"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div> */}
                    <div className="form-group">
                      <label
                        htmlFor="inputImage"
                        className="col-sm-2 control-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="image"
                          className="form-control"
                          id="inputImage"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit Profile" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="changePassword">
                  <form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputOld"
                        className="col-sm-2 control-label"
                      >
                        Old Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="oldPassword"
                          className="form-control"
                          id="currp"
                          placeholder="Old Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNew"
                        className="col-sm-2 control-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          id="newp"
                          placeholder="New Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNewC"
                        className="col-sm-2 control-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="cnewPassword"
                          className="form-control"
                          id="confirmp"
                          placeholder="Confirm Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <input
                          type="submit"
                          className="btn btn-success"
                          name="submit"
                          value="Update Profile"
                          onClick={() => {
                            changePassword();
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoinPanelProfile;
