import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";

const AddVip = () => {
  const navigate = useNavigate();

  const subbbb = (e) => {
    e.preventDefault();
    var a = $("input[name='levelreq']").val();
    var b = $("input[name='title']").val();
    var c = $("input[name='price']").val();
    var d = $("input[name='valid']").val();
    var e = $("input[name='svga']")[0].files[0]; // Access the file object
    var f = $("input[name='image']")[0].files[0]; // Access the file object
    let text = "This field cannot be empty";

    if (a == "") {
      $(".form-error1").html(text);
    }
    if (b == "") {
      $(".form-error2").html(text);
    }
    if (c == "") {
      $(".form-error3").html(text);
    }
    if (d == "") {
      $(".form-error4").html(text);
    }
    if (e == "") {
      $(".form-error5").html(text);
    }
    if (f == "") {
      $(".form-error6").html(text);
    } else if (a != "" && b != "" && c != "" && d != "" && e != "" && f != "") {
      // let formData = new FormData(e.target); // Use e.target to access the form element
      let formData = new FormData();
      formData.append("levelreq", a);
      formData.append("title", b);
      formData.append("price", c);
      formData.append("valid", d);
      formData.append("svga", e);
      formData.append("image", f);

      $.ajax({
        url: `${process.env.REACT_APP_BASEURL}/admin/vip/addVip`,
        type: "post",
        dataType: "json",
        data: formData,
        contentType: false,
        processData: false,
        success: function (res) {
          if (res.success === 1) {
            toast.success(res.message);
            setTimeout(() => {
              navigate("/admin/manageVip");
            }, 1000);
          } else {
            toast.error(res.message);
          }
        },
      });
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Add Vip</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageVip">Manage Vip</Link>
          </li>
          <li className="active">Add Vip</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Level Required*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="levelreq"
                      placeholder="level required"
                      onChange={() => {
                        $(".form-error1").html("");
                      }}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      onChange={() => {
                        $(".form-error2").html("");
                      }}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Price*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      placeholder="Price"
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Valid For*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="valid"
                      placeholder="For Days"
                      onChange={() => {
                        $(".form-error4").html("");
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Svga*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="svga"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={() => {
                        $(".form-error6").html("");
                      }}
                    />
                    <div className="form-error6 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddVip;
