import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import axios from "axios";

const SecretKey = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [readOnly, setReadOnly] = useState(true);
  const { getSecretKey, secret, id } = useContext(global);
  const [stripeKey, setStripeKey] = useState("");
  const [sightEngineKey, setSightEngineKey] = useState("");
  const [sightEngineUser, setSightEngineUser] = useState("");
  const [googleLogin, setGoogleLogin] = useState("");
  const [agoraId, setAgoraId] = useState("");
  const [agoraCertificate, setAgoraCertificate] = useState("");
  const toggleReadOnly = () => {
    setReadOnly(!readOnly);
  };
  // console.log(id);

  const response = async (value) => {
    try {
      // e.preventDefault();
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASEURL1}/admin/secretfile/${id}`,
        value
        // {
        //  googleClientId: req.body.googleClientId,
        // agoraCertificate: req.body.agoraCertificate,
        // agoraId: req.body.agoraId,
        // stripeKey: req.body.stripeKey,
        // apiKeyForFastSMS: req.body.apiKeyForFastSMS,
        // sightEngineApiUser: req.body.sightEngineApiUser,
        // sightEngineApiSecret: req.body.sightEngineApiSecret,
        // }
      );
      console.log(data);
      if (data.success == 1) {
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log(id);
  useEffect(() => {
    getSecretKey();
  }, []);
  useEffect(() => {
    response();
  }, [id]);
  // console.log(id);
  return (
    <>
      <section className="content-header">
        <h1>Secret Keys</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">
              <i className="fa fa-dashboard" /> Home
            </a>
          </li>
          <li className="active">User profile</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#settings" data-toggle="tab">
                    Google Login
                  </a>
                </li>
                <li>
                  <a href="#changePassword" data-toggle="tab">
                    Agora
                  </a>
                </li>
                <li>
                  <a href="#thirddd" data-toggle="tab">
                    Sight Engine
                  </a>
                </li>
                <li>
                  <a href="#forutttrh" data-toggle="tab">
                    Stripe
                  </a>
                </li>
                <li>
                  <a href="#fifthh" data-toggle="tab">
                    Fast 2 sms
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="active tab-pane" id="settings">
                  <form
                    className="form-horizontal"
                    method=""
                    encType="multipart/form-data"
                    onSubmit={() => {}}
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Client Id
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="name"
                          className="form-control"
                          id="inputName"
                          placeholder=""
                          defaultValue={secret?.googleClientId}
                          readOnly={readOnly}
                          onChange={(e) => {
                            setGoogleLogin(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const newKey = {
                                ...secret,
                                googleClientId:
                                  googleLogin.length > 0
                                    ? googleLogin
                                    : secret.googleClientId,
                              };
                              response(newKey);
                            }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="changePassword">
                  <form
                    className="form-horizontal"
                    method=""
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        App Certificate
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="name"
                          className="form-control"
                          id="inputName"
                          placeholder=""
                          defaultValue={secret?.agoraCertificate}
                          readOnly={readOnly}
                          onChange={(e) => {
                            setAgoraCertificate(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        App Id
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="name"
                          className="form-control"
                          id="inputName"
                          placeholder=""
                          defaultValue={secret?.agoraId}
                          readOnly={readOnly}
                          onChange={(e) => {
                            setAgoraId(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const newKey = {
                                ...secret,
                                agoraId:
                                  agoraId.length > 0 ? agoraId : secret.agoraId,
                                agoraCertificate:
                                  agoraCertificate.length > 0
                                    ? agoraCertificate
                                    : secret.agoraCertificate,
                              };
                              response(newKey);
                            }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="thirddd">
                  <form
                    className="form-horizontal"
                    method=""
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        API User
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="sightEngineApiUser"
                          className="form-control"
                          id="inputName"
                          defaultValue={secret?.sightEngineApiUser}
                          readOnly={readOnly}
                          onChange={(e) => {
                            setSightEngineUser(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Secret Key
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="sightEngineApiSecret"
                          className="form-control"
                          id="inputName"
                          defaultValue={secret?.sightEngineApiSecret}
                          placeholder=""
                          readOnly={readOnly}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setSightEngineKey(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              const newKey = {
                                ...secret,
                                sightEngineApiSecret:
                                  sightEngineKey.length > 0
                                    ? sightEngineKey
                                    : secret.sightEngineApiSecret,
                                sightEngineApiUser:
                                  sightEngineUser.length > 0
                                    ? sightEngineUser
                                    : secret.sightEngineApiUser,
                              };
                              response(newKey);
                            }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="forutttrh">
                  <form
                    className="form-horizontal"
                    method="PUT"
                    // encType="multipart/form-data"
                    // onSubmit={() => {
                    // const newKey = { ...secret, stripeKey };
                    // response(newKey);
                    // }}
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Api Key
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="stripeKey"
                          className="form-control"
                          id="inputName"
                          placeholder=""
                          defaultValue={secret?.stripeKey}
                          // readOnly={readOnly}
                          onChange={(e) => {
                            setStripeKey(e.target.value);
                          }}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              const newKey = { ...secret, stripeKey };
                              response(newKey);
                            }}
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="fifthh">
                  <form
                    className="form-horizontal"
                    method=""
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Api key
                      </label>
                      <input type="hidden" name="id" />
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="name"
                          className="form-control"
                          id="inputName"
                          defaultValue={secret?.apiKeyForFastSMS}
                          placeholder=""
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecretKey;
