import React from "react";

const PopModal = ({ closeModal, title, logic }) => {
  return (
    <>
      <div
        style={{
          display: "block",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
          zIndex: 1050, // High z-index to ensure it is above other content
        }}
        onClick={closeModal} // Close modal when clicking outside
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            width: "500px",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >
          <h5
            style={{
              fontSize: "3rem",
              // fontWeight: "lighter",
              textAlign: "center",
            }}
          >
            {title}?
          </h5>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "5rem",
              paddingRight: "5rem",
              marginTop: "2rem",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "blue",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                logic();
                closeModal();
              }}
            >
              Yes
            </button>
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopModal;
