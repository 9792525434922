import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const AdminBannedUsers = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;

  const [hostRecord, setHostRecord] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [count,setCount] = useState(0)
  const [searchQuery, setSearchQuery] = useState("");
  // console.lg  og(hostRecord);
  useEffect(() => {
    fetchAdminHostRecord();
  }, [entriesPerPage]);

  
  // console.log(currentPage);
  useEffect(() => {
    fetchAdminHostRecord();
  }, [page]);
  const renderSerialNumber = (index) => {
    return (page - 1) * entriesPerPage + index + 1;
  };
  const fetchAdminHostRecord = async () => {
    try {
        setHostRecord([])
      const data = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/bannedUsers?page=${page}&limit=${entriesPerPage}`
      );

      if (data) {
        setHostRecord(data.data.details);
        setCount(data.data.total);
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };
 
  const agencyadminlistsearch = async (query = "") => {
    try {
      setHostRecord([])
      const response = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/bannedUserssearch`, {
        params: {
          search: query,
        },
      });
      setHostRecord(response.data.details);
      setCount(response.data.total);

    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value)
      if(value!="")
      agencyadminlistsearch(value);
    else{
      fetchAdminHostRecord()
    }
    }, 1000),
    []
  );
  return (
    <>
      <section className="content-header">
        <h1>Banned Users</h1>
      
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      ent
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                </div>
               
             

                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {hostRecord.length==0?"Loading...":hostRecord?.map((host, i) => (
                      <tr key={host._id}>
                       
                        <td>{renderSerialNumber(i)}</td>
                       
                        <td>{host.username}</td>
                        <td>{host.name}</td>

                        <td>{host.number}</td>

                        <td>
                          <span
                            className={`label label-danger`}
                          >
                            Banned
                          </span>
                        </td>
                        <td>
                          <ul className="admin-action btn btn-default">
                            <li className="dropdown">
                              <a className="dropdown-toggle" style={{ color: "black" }} data-toggle="dropdown" href="#" aria-expanded="false">
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                <li role="presentation">
                                  <Link role="menuitem" tabIndex={-1} to={`/admin/ViewUser?id=${host._id}`}>
                                    View
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}

                <div>
                  <ul className="pagination">
                    <div>Total Entries : {count}</div>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPage((prev) => Math.max(prev - 1, 1));
                        }}
                      >
                        Previous
                      </span>
                    </li>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if(Math.ceil(count / entriesPerPage) > page)
                          setPage((prev) => prev + 1);
                        }}
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminBannedUsers;
