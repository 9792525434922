import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify'

const AddCommission = () => {

  const navigate = useNavigate();

  const subbbb = (e) => {
    e.preventDefault();

    var a = $("input[name='coinStart']").val();
    var b = $("input[name='coinEnd']").val();
    var d = $("input[name='targetCommission']").val();
    var e = $("input[name='extraCommission']").val();
    let text = "This field cannot be empty";

    if (!a) {
      $('.form-error1').html(text);
    }
    else if (!b) {
      $('.form-error2').html(text);
    }

    else if (!d) {
      $('.form-error4').html(text);
    }
    else if (a != '' && b != '' && d != '') {
      let formdata = {
        coinStart: a,
        coinEnd: b,
        targetCommission: d,
        extraCommission:e,
      };
      
      fetch('https://mastishortss.com/video/admin/commission/addCommission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formdata)
      })
      .then(response => response.json())
      .then(res => {
        if (res.success === 1) {
          toast.success(res.message);
          setTimeout(() => {
            navigate('/admin/viewcommission');
          }, 1000);
        } else {
          toast.error(res.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error(error.message);
      });
      
    }
  }


  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: '20px' }}>
          Add Commission
        </h1>
        <ol className="breadcrumb" style={{ marginBottom: '20px' }}>
          <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
          <li><Link to="/admin/viewcommission">Commission</Link></li>
          <li className="active">Add Commission</li>
        </ol>
      </section>
      <section className="content" style ={{ padding: '0 15px' }}>
        <div className="row">
          <div className="col-md-12">
            <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Coin Start</label>
                    <input type="number" className="form-control" name="coinStart" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error1').html('');
                    }} />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Coin End</label>
                    <input type="number" className="form-control" name="coinEnd" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error2').html('');
                    }} />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Target Commission (USD)</label>
                    <input type="number" step="0.01" className="form-control" name="targetCommission" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error4').html('');
                    }} /> 
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Extra Commission (USD)</label>
                    <input type="number" step="0.01" className="form-control" name="extraCommission" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error4').html('');
                    }} /> 
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddCommission