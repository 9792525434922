import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { value } from "../../config/data";


const FamilyWithdrawalbdadmin = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  // const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [loading,setLoading] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);


  // console.log(widthdrawalList)
  const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = (total.totalAmount);
    return acc + convertedAmount;
  }, 0); 

  const currentDate = new Date();
// console.log(widdthdrawalList)
  // Calculate the date two days before the current date
  const startDateObj = new Date(currentDate);
  startDateObj.setDate(currentDate.getDate() - 7);
  
  // Calculate the date one day before the current date
  const endDateObj = new Date(currentDate);
  endDateObj.setDate(currentDate.getDate());
  
  // Format the start date to YYYY-MM-DD
  const startYear = startDateObj.getFullYear();
  const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDateObj.getDate()).padStart(2, '0');
  const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
  
  // Format the end date to YYYY-MM-DD
  const endYear = endDateObj.getFullYear();
  const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDateObj.getDate()).padStart(2, '0');
  const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
  

  // if (startDate === null) {
  //   setStartDate(startFormattedDate);
  // }
  if (endDate === null) {
    setEndDate(endFormattedDate);
  }

  // console.log(startDate)
  let currentDate1 = new Date();
  let currentDay1 = currentDate1.getDay();
  let prevMonday = new Date(currentDate1);
  prevMonday.setDate(currentDate.getDate() - ((currentDay1 + 6) % 7));
  
let lastMonday = startDate !== null ? startDate : prevMonday.toISOString().split("T")[0];

// console.log(startDate)
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/withdrawal/familyWithBD?start=${lastMonday}&end=${endDate}`)
   
    // console.log(data)
    if(data.success == 1) {
      setLoading(false)
      setWithdrawalLists(data.details)

    }
 } catch (error) {
    console.log(error.message)
 }
  }

  // console.log(process.env.REACT_USD);


  useEffect(() => {
    // users();
    getBdLists()
  }, [lastMonday,endDate,startDate]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
 
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math.ceil(widthdrawalList.length / entriesPerPage);
 
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(widthdrawalList.map((item) => item.bd._id));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  }
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };
  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        {/* {loading && <p>Loading</p>} */}
        <h1>Bd Family Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>

                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                         Start Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setStartDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          End Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setEndDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
           
              <div className="box-body">
                <div style={{float:"right",fontWeight:"bolder",marginRight:"2rem" }}>
                  <span>Total : {(totolSumOfWithdrawal).toFixed(2)} Rs /-</span>
                </div>
                
              <div style={{border:"1px solid white",display:"flex",gap:"2rem"}}>
                  <div className="" >
                    <span style={{fontWeight:"bold"}}>From :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{lastMonday}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <span style={{fontWeight:"bold"}}>To :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{endDate}</span>
                  </div>
                </div>
                {/* <!-- Content here --> */}
                <div className="col-md-1">
                  {/* <button className="btn btn-info" style={{ outline: "none" }} onClick={toggleSelectAll}>
                    {selectAll ? "Deselect All" : "Select All"}
                  </button> */}
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    {/* <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th> */}
                      <th>Sr.</th>
                      <th>Bd Id</th>
                      <th>Bd Name</th>
                      <th>INR</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" >Loading...</h6> </td> :   Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr key={index}>
                            {/* <td>
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(item.bd._id)}
                                onChange={() => handleCheckboxChange(item.bd._id)}
                              />
                            </td> */}
                            
                            <td>{i}</td>
                         
                            <td>{item?.bd}</td>
                            <td>{item?.bdName}</td>
                            <td>{item?.totalAmount}</td>

                        
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                {/* ... (your existing dropdown menu items) */}
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/storedwithdrawalfamilyAAlists?bd=${item?.bd}&start=${lastMonday}&end=${endDate}`}
                                      >
                                        View
                                      </Link>
                                    </li>
                               
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                      {widthdrawalList.length == 0 && <h4 style={{textAlign:"center"}} colSpan={4}>No Data found</h4>}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
            
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
// Define active style object
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default FamilyWithdrawalbdadmin;
