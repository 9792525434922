import React from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'

const AddVipPack = () => {

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get('id');

    const navigate = useNavigate();

    const subbbb = (e) => {
        e.preventDefault();
        var a = $("input[name='name']").val();
        var c = $("input[name='image']").val();
        let text = "This field cannot be empty";
        if(a == ''){
            $('.form-error1').html(text);
        }
        if(c == ''){
            $('.form-error3').html(text);
        }else if(c != '' && a != ''){
            let formData = new FormData(e.target); // Use e.target to access the form element
            $.ajax({
                url: `${process.env.REACT_APP_BASEURL}/admin/vip/addVipPack`,
                type: "post",
                dataType: "json",
                data: formData,
                contentType: false,
                processData: false,
                success: function(res) {
                  if(res.success===1){
                      toast.success(res.message)
                      setTimeout(()=>{
                            navigate(`/admin/viewVipPack?id=${userId}`);
                      },1000)
                  }else{
                      toast.error(res.message)
                  }
                }
            });
        }
    }

    return (
        <>
            <section className="content-header">
                <h1>Add Vip</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageVip">Manage Vip</Link></li>
                    <li className="active">Add Vip</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-10">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add</h3>
                                </div>
                                <div className="box-body">
                                    <input type="hidden" className="form-control" name="vipId" value={userId}/>
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="name" placeholder="Title" onChange={()=>{
                                            $('.form-error1').html('');
                                        }} />
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input type="file" className="form-control" name="image" onChange={()=>{
                                            $('.form-error3').html('');
                                        }}/>
                                        <div className="form-error3 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddVipPack