import React from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'

const ApplyHost = () => {
    
    const navigate = useNavigate();

    const subbbb = (e) => {
        e.preventDefault();
        var a = $("input[name='username']").val();
        var b = $("input[name='agencyCode']").val();
        // var d = $("input[name='phone']").val();
        let text = "This field cannot be empty";
        if(a == ''){
            $('.form-error1').html(text);
        }
        if(b == ''){
            $('.form-error2').html(text);
        }
        // if(d == ''){
        //     $('.form-error4').html(text);
        // }
        else if(a!= '' && b!='' ){
            let formData = new FormData(e.target); // Use e.target to access the form element
            $.ajax({
                url: `${process.env.REACT_APP_BASEURL}/agency/host/applyhost`,
                type: "post",
                dataType: "json", 
                data: {'username':a,'agencyCode':b , 'status':'1'},
                success: function(res) {
                  if(res.success===1){
                      toast.success(res.message)
                      setTimeout(()=>{
                            navigate('/admin/approvedHost')
                      },1000)
                  }else{
                      toast.error(res.message)
                  }
                }
            });
        }
    }

    return (
        <>
            <section className="content-header">
                <h1>
                    Add Host
                </h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li className="active">Add Host</li>
                </ol>
            </section> 
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add</h3>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>User Name*</label>
                                                <input type="text" className="form-control input" name="username"  placeholder='Enter user name'onChange={()=>{
                                            $('.form-error1').html('');
                                        }} />
                                        <div className="form-error1 text-danger"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>Agency Code*</label>
                                                <input type="text" className='form-control input' name="agencyCode" placeholder='Enter agency code' onChange={()=>{
                                            $('.form-error2').html('');
                                        }} />
                                        <div className="form-error1 text-danger"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger" id="clearBtn">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit"  defaultType="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ApplyHost