import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const AgencyAdminHostList = ({ status }) => {
  const baseUrl = process.env.REACT_APP_BASEURL2;

  const [list, setList] = useState([]);
  const [reload, setReload] = useState(false);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const { totalPages, page, setPage } = useContext(global);

  const agencyadminlist = async (query = "") => {
    try {
      setList([])
      setSearchQuery("")
      const response = await axios.get(`${baseUrl}/agency/host/getpenagencyadminlist`, {
        params: {
          status,
          agadmin: JSON.parse(sessionStorage.getItem("agencyadmin")).uniqueId,
          page,
          limit,
          // search: query,
        },
      });
      setList(response.data.details);
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };
  const agencyadminlistsearch = async (query = "") => {
    try {
      setList([])
      const response = await axios.get(`${baseUrl}/agency/host/searchHosts`, {
        params: {
          status,
          agadmin: JSON.parse(sessionStorage.getItem("agencyadmin")).uniqueId,
          search: query,
        },
      });
      setList(response.data.details);
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value)
      agencyadminlistsearch(value);
    }, 1000),
    []
  );

  useEffect(() => {
    agencyadminlist(searchQuery);
  }, [status, reload, page, limit]);

  const handleApproval = async (status, id) => {
    try {
      const response = await axios.get(`${baseUrl}/agency/host/hostrequestsentagadmin`, {
        params: { status, id },
      });

      if (response.data.success === 1) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      setReload(!reload);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => setLimit(Number(e.target.value))}
                      className="form-control"
                    >
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                </div>
                <table id="example1" className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Agency Code</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {list.length === 0 ? (
                      "No data available"
                    ) : (
                      list.map((host, i) => (
                        <tr key={host._id}>
                          <td>{(count - limit * (page - 1)) - i}</td>
                          <td>{new Date(host?.updatedAt).toLocaleString()}</td>
                          <td>{host?.username}</td>
                          <td>{host?.realName}</td>
                          <td>{host?.agencyCode}</td>
                          <td>
                            <span
                              className={`label ${
                                host?.status == 0
                                  ? "label-info"
                                  : host?.status == 1
                                  ? "label-success"
                                  : host?.status == 3
                                  ? "label-primary"
                                  : host?.status == 4
                                  ? "label-primary"
                                  : "label-danger"
                              }`}
                            >
                              {host.status == 0
                                ? "Pending"
                                : host.status == 1
                                ? "Approved"
                                : host.status == 3
                                ? "Approved by Agency"
                                : host.status == 4
                                ? "Pending by Bd"
                                : "Rejected"}
                            </span>
                          </td>
                          <td>
                            <ul
                              className="admin-action btn btn-default"
                              style={{
                                backgroundColor: "#f4f4f4",
                                color: "#fff !important",
                              }}
                            >
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  style={{ color: "black" }}
                                  data-toggle="dropdown"
                                  href="#"
                                  aria-expanded="false"
                                >
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  {status === 0 ? (
                                    <>
                                      <li
                                        role="presentation"
                                        onClick={() => handleApproval(1, host._id)}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Approved
                                        </Link>
                                      </li>
                                      <li
                                        role="presentation"
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to reject"
                                          );
                                          if(confirmDelete) 
                                          handleApproval(2, host._id)}}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Reject
                                        </Link>
                                      </li>
                                    </>
                                  ) : status === 1 ? (
                                    <>
                                      {/* <li
                                        role="presentation"
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to reject"
                                          );
                                          if(confirmDelete) 
                                          handleApproval(2, host._id)
                                        }}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Reject
                                        </Link>
                                      </li> */}
                                    </>
                                  ) : null}
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div>
                  <ul className="">
                    <div className="row">
                      <div className="col-md-5 text-bold">
      
                          Total entries : {count} 
                  
                      </div>
                      <div className="pagination col-md-5">
                      <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) => (prev < Math.ceil(count / limit) ? prev + 1 : prev))
                        }
                      >
                        Next
                      </span>
                    </li>
                      </div>
                    </div>
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencyAdminHostList;
