import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { value } from '../../config/data'
import { saveAs } from 'file-saver';

const BdListWithdrawal = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList, setWithdrawalLists] = useState([])
  const [loading, setLoading] = useState(false)
  let [searchParams] = useSearchParams();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(widthdrawalList.map(item => ({
        _id: item._id,
        uniqueId: item.agency,
        name: item.agencyName,
        admin: adminData,
        totalCoins: item.totalAmount,
        totalINR: parseFloat(((((item?.totalDollor) * value.USDINR)).toFixed(2))),
        agencyadminCom: 0,
        totalComm: parseFloat(((((item?.totalDollor) * value.USDINR) * value.agencyComm).toFixed(2))),
      })));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(selected => selected._id === item._id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(selected => selected._id !== item._id));
    } else {
      setSelectedItems([...selectedItems, {
        _id: item._id,
        uniqueId: item.agency,
        name: item.agencyName,
        admin: adminData,
        totalCoins: item.totalAmount,
        totalINR: parseFloat(((((item?.totalDollor) * value.USDINR)).toFixed(2))),
        agencyadminCom: 0,
        totalComm: parseFloat(((((item?.totalDollor) * value.USDINR) * value.agencyComm).toFixed(2))),
      }]);
    }
  };
  const exportToCsv = () => {
    try {
      const csvContent = selectedItems.map(item => ({
        uniqueId: item.uniqueId,
        name: item.name,
        admin: item.admin,
        totalCoins: item.totalCoins,
        totalINR: item.totalINR,
        agencyadminCom: 0,
        totalComm: item.totalComm,
      }));

      const csvRows = [
        ["Agency Id", "Agency Name", "Created By", "Total Withdrawal", "INR", "AgencyAdmin Commission", "Agency Commission"],
        ...csvContent.map(item => Object.values(item))
      ];
 
      const csvString = csvRows.map(row => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'exported_data.csv');
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const adminData = searchParams.get('admin');
  const bd = searchParams.get('bd');
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');
  // console.log(startDate)
  // console.log(endDate)
  // console.log(adminData)
  //Get Bds lists 
  const getBdLists = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/withdrawal/WithAg?start=${startDate}&end=${endDate}&agencyadmin=${adminData}&bd=${bd}`)
      // const {data} = await axios.get(`https://mastishortss.com/video/admin/withdrawal/agency?start=${startDate}&end=${endDate}&admin=${adminData}`)

      // console.log(data)
      if (data.success == '1') {
        setLoading(false)
        setWithdrawalLists(data.details)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, []);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  // const currentUsers = user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers =
  //   searchData.length > 0
  //     ? searchData.slice(indexOfFirstUser, indexOfLastUser)
  //     : user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers = widthdrawalList.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  // const totalPages = Math.ceil(user.length / entriesPerPage);
  const totalPages = Math.ceil(
    user?.length / entriesPerPage
  );

  //   const searhUser = async () => {
  //     try {
  //     //   setSearchData(user);
  //       const { data } = await axios.get(
  //         `${process.env.REACT_APP_BASEURL1}/admin/search?search=${search}`
  //       );
  //       // console.log(data);
  //     //   setSearchData(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };


  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Bd Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-6 ">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) =>
                          setEntriesPerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </select>
                    </div>

                  </div>
                </div>
              </form>
              <div className="box-body">
                {/* <!-- Content here --> */}
                <div className="col-md-2">
                  <button className="btn btn-info" style={{ outline: "none" }} onClick={toggleSelectAll}>
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>
                <div>
                  <button type="submit" className="btn btn-info" onClick={exportToCsv} style={{ marginRight: "1rem" }}>
                    Export Data
                  </button>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th>
                      <th>Sr.</th>
                      {/* <th>Image</th> */}
                      <th>Agency Id</th>
                      <th>Agency Name</th>
                      <th>Created By</th>
                      <th>Total Withdrawal Coin</th>
                      <th>INR</th>
                      <th>AgencyAdmin Commission</th>
                      <th>Agency Commission</th>
                      <th>Action</th>
                      {/* <th>Phone</th>
                      <th>Coins</th>
                      <th>Receiving Coins</th>
                      <th>Status</th>
                      <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ? <td colSpan={4} style={{ textAlign: 'center' }}><h6 className="" style={{ width: "100%", margin: "0 auto" }}>Loading...</h6> </td> : Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedItems.some(selected => selected._id === item._id)}
                                onChange={() => handleCheckboxChange(item)}
                              />
                            </td>
                            <td>{i}</td>
                            {/* <td>
                              <img
                                src={`${item?.image}`}
                                style={{ width: "50px",height:"50px" }}
                                alt="user"
                              />
                            </td> */}
                            <td>{item?.agency}</td>
                            <td>{item?.agencyName}</td>
                            <td>{adminData}</td>
                            <td>{item?.totalAmount}</td>
                            <td><span style={{ fontWeight: "bold" }}>Rs </span>{parseFloat(((((item?.totalDollor) * value.USDINR)).toFixed(2)))}</td>
                            <td>0</td>
                            <td><span style={{ fontWeight: "bold" }}>Rs </span>{parseFloat(((((item?.totalDollor) * value.USDINR) * value.agencyComm).toFixed(2)))}</td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                {/* ... (your existing dropdown menu items) */}
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/bd/hostlists?agency=${item?.agency}&start=${startDate}&end=${endDate}&agencyadmin=${adminData}&bd=${bd}`}
                                      >
                                        View
                                      </Link>
                                    </li>
                                    {/* <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="">Edit</Link>
                                    </li>
                                    <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="">Assign Badges</Link>
                                    </li>
                                    <li>
                                      <Link role="menuitem" tabIndex={-1} onClick="return confirm(&quot;Once you've deleted 	this account, the user () will no longer be able to log in to the site or apps. This action cannot be undone.&quot;);" to="">
                                        Delete
                                      </Link>
                                    </li>
                                    <li role="presentation" className="divider" />
                                    <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="/user/giftHistory/">Received Gift History</Link>
                                    </li>
                                    <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="/user/sendGiftHistory/">Send Gift History</Link>
                                    </li>
                                    <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="/user/purchaseCoinHistory/">Coin History</Link>
                                    </li>
                                    <li role="presentation">
                                      <Link role="menuitem" tabIndex={-1} to="/user/UserLiveHistory/">Live History</Link>
                                    </li>
                                    <li role="presentation" className="divider" />
                                    <li role="presentation">
                                      <Link role="menuitem" onClick="return confirm( - Do you want this user&quot;);" tabIndex={-1} to="/user/status/">
                                      </Link>
                                    </li> */}
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""
                          }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries:{" "}
                      {/* <b>
                        {searchData?.length > 0
                          ? searchData?.length
                          : user?.length}
                      </b> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
// Define active style object
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default BdListWithdrawal;
