import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditEvent = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const eventId = params.get("id");// Get the eventId from the URL
  const [formFields, setFormFields] = useState({
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    gifts: [],
    image: null,
    formImage: null,
    leaderboardImage: null,
  });
  const [giftsOptions, setGiftsOptions] = useState([]);
  const navigate = useNavigate();

  // Fetch the event details and populate the form
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `https://mastishortss.com/v5/admin/banner/getEventById?eventId=${eventId}`
        );
        if (response.data.success == 1) {
          const event = response.data.details;
          setFormFields({
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            startTime: event.startTime,
            endTime: event.endTime,
            gifts: event.gifts.map((gift) => gift.id),
            image: event.image,
            formImage: event.formImage,
            leaderboardImage: event.leaderboardImage,
          });
        }
      } catch (error) {
        console.error("Error fetching event details:", error);
        toast.error("Failed to fetch event details");
      }
    };

    const fetchGifts = async () => {
      try {
        const response = await axios.get(
          "https://mastishortss.com/v3/admin/livegifts/getlivegifts"
        );
        if (response.data.success === 1) {
          setGiftsOptions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching gifts:", error);
      }
    };

    fetchEventDetails();
    fetchGifts();
  }, [eventId]);

  const handleInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const handleGiftSelection = (giftId) => {
    const selectedGifts = [...formFields.gifts];
    if (selectedGifts.includes(giftId)) {
      const updatedGifts = selectedGifts.filter((id) => id !== giftId);
      setFormFields({ ...formFields, gifts: updatedGifts });
    } else {
      if (selectedGifts.length < 3) {
        setFormFields({ ...formFields, gifts: [...selectedGifts, giftId] });
      } else {
        toast.error("You can select a maximum of 3 gifts.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("gifts", JSON.stringify(formFields.gifts)); // Sending gift IDs as an array

    try {
      event.target.reset();

      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL5}/admin/banner/editEvents?eventId=${eventId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success === 1) {
        toast.success("Event successfully updated");
        navigate("/admin/GetEvents");
      } else {
        toast.error("Failed to update event");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to update event");
    }
  };

  return (
    <>
      <section className="content-header">
        <ol className="breadcrumb">
          <li>
            <i className="fa fa-dashboard"></i> Home
          </li>
          <li className="active">Edit Event</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Event</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Image*</label>
                    <input type="file" className="form-control" name="image" />
                    {formFields.image && (
                      <img src={formFields.image} alt="Event" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Register Form Image*</label>
                    <input type="file" className="form-control" name="formImage" />
                    {formFields.formImage && (
                      <img src={formFields.formImage} alt="Form" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Leaderboard Image*</label>
                    <input type="file" className="form-control" name="leaderboardImage" />
                    {formFields.leaderboardImage && (
                      <img src={formFields.leaderboardImage} alt="Leaderboard" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formFields.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={formFields.startDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={formFields.endDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Time*</label>
                    <input
                      type="time"
                      className="form-control"
                      name="startTime"
                      value={formFields.startTime}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Time*</label>
                    <input
                      type="time"
                      className="form-control"
                      name="endTime"
                      value={formFields.endTime}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Gifts*</label>
                    <div className="mb-3">
                      {giftsOptions.map((gift) => (
                        <div key={gift._id} className="form-check position-relative">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={gift._id}
                            checked={formFields.gifts.includes(gift._id)}
                            onChange={() => handleGiftSelection(gift._id)}
                          />
                          <label className="form-check-label">
                            <img
                              src={`${process.env.REACT_APP_BASEURL1}${gift.giftimage}`}
                              alt={gift.title}
                              width="30"
                              className="me-2"
                            />
                            <span>{gift.title}</span> - {gift.coin} coins
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success pull-right"
                    >
                      Update Event
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditEvent;
