import React, { useState, useEffect ,useCallback} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const BdBanneduser = () => {
  const [agencyadimindata, setAgencyAdminData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const baseUrl = process.env.REACT_APP_BASEURL3;
  const bdId = JSON.parse(sessionStorage.getItem("bd"));
  const uniqueId = bdId ? bdId.uniqueId : "";
  const createBy = bdId ? bdId.createBy : "";
  const [searchQuery, setSearchQuery] = useState("");

  const manageagencadminApi = async () => {
    try {
      setAgencyAdminData([])
      const agencyAdminResponse = await axios.get(
        `${baseUrl}/agency/host/bannedUsersBd?page=${page}&limit=${limit}&bdname=${uniqueId}`,
      );
      if (
        agencyAdminResponse &&
        agencyAdminResponse.data &&
        agencyAdminResponse.data.details
      ) {
        setAgencyAdminData(agencyAdminResponse.data.details);
        setCount(agencyAdminResponse.data.total)
        toast.success("list successfully retrieved");
      } else {
        toast.error("Failed to retrieve  list");
      }
    } catch (error) {
      toast.error("Failed to get  list");
    }
  };

 
  const agencyadminlistsearch = async (query = "") => {
    try {
      setAgencyAdminData([])
      const response = await axios.get(`${baseUrl}/agency/host/bannedUsersBdsearch`, {
        params: {
          bdname: JSON.parse(sessionStorage.getItem("bd")).uniqueId,
          search: query,
        },
      });
      setAgencyAdminData(response.data.details);
      setCount(response.data.total)
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    manageagencadminApi();
  }, [limit,page]);
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value)
      agencyadminlistsearch(value);
    }, 1000),
    []
  );
  return (
    <>
      <section className="content-header">
        <h1>Manage Banned Users</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
             
            </div>
            <div className="box">
              <div className="box-body">
              <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => setLimit(Number(e.target.value))}
                      className="form-control"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                <div className="success-message"></div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Username</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {agencyadimindata.length === 0 ? (
                      "Loading..."
                    ) : agencyadimindata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img src={`${process.env.REACT_APP_BASEURL1}${item.userDetails.picture}`} alt="" width={"40px"} height={"40px"} style={{borderRadius:"100%"}}/>
                        </td>
                        <td>{item.userDetails.username}</td>
                       
                        {/* <td>
                          <div className="dropdown">
                            <button
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Action
                              <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right">
                              <li>
                                <Link
                                  to={`/bd/viewagencyadmin/?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/bd/editagencyadimin?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Edit
                                </Link>
                              </li>
                             
                            </ul>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                      <div className="col-md-5 text-bold">
      
                          Total entries : {count} 
                  
                      </div>
                      <div className="pagination col-md-5">
                      <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) => (prev < Math.ceil(count / limit) ? prev + 1 : prev))
                        }
                      >
                        Next
                      </span>
                    </li>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BdBanneduser;
