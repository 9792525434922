// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios"; // corrected import statement
// const baseUrl = process.env.REACT_APP_BASEURL;
// const LiveTime = () => {
//   const [editable, setEditable] = useState(false);
//   const [days, setDays] = useState("");
//   const [timeInMinutes, setTimeInMinutes] = useState("");

//   useEffect(() => {
//     // Fetch data from the backend API when the component mounts
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${baseUrl}/admin/livetime/getlivetime`
//         );
//         // Adjust the API endpoint as needed
//         const responseData = response.data;
//         console.log(responseData);
//         if (responseData.success) {
//           // Update state with the fetched data
//           setDays(responseData.details.days); // Assuming the days data is received as responseData.days
//           setTimeInMinutes(responseData.details.time); // Assuming the timeInMinutes data is received as responseData.timeInMinutes
//         } else {
//           console.error("Error:", responseData.message);
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array to only run this effect once on component mount

//   const handleEditClick = () => {
//     setEditable(true);
//   };

//   const handleCancelClick = () => {
//     setEditable(false);
//     // Reset input values on cancel
//     setDays("");
//     setTimeInMinutes("");
//   };

//   const handleSaveClick = async () => {
//     setEditable(false);

//     try {
//       const response = await axios.get(
//         `${baseUrl}/admin/livetime/editlivetime`
//       );
//       // Adjust the API endpoint as needed
//       const responseData = response.data;
//       console.log(responseData);
//       if (responseData.success) {
//         // Update state with the fetched data
//         setDays(responseData.details.days); // Assuming the days data is received as responseData.days
//         setTimeInMinutes(responseData.details.time); // Assuming the timeInMinutes data is received as responseData.timeInMinutes
//       } else {
//         console.error("Error:", responseData.message);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//     // Perform save operation here with days and timeInMinutes values
//     // For example, you can send them to an API or update state in parent component
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     // Update state based on input field name
//     if (name === "days") {
//       setDays(value);
//     } else if (name === "timeInMinutes") {
//       setTimeInMinutes(value);
//     }
//   };

//   return (
//     <>
//       <section className="content-header">
//         <h1>Manage Live Time</h1>
//         <ol className="breadcrumb">
//           <li>
//             <Link to="/admin">
//               <i className="fa fa-dashboard"></i> Home
//             </Link>
//           </li>
//           <li>
//             <Link to="/admin/manageTag">Manage Live Time</Link>
//           </li>
//         </ol>
//       </section>
//       <section className="content">
//         <div className="row">
//           <div className="col-md-12">
//             <form
//               role="form"
//               method="post"
//               encType="multipart/form-data"
//               id="form"
//             >
//               <div className="box box-warning">
//                 <div className="box-header with-border">
//                   <h3 className="box-title">Manage</h3>
//                 </div>
//                 <div className="box-body">
//                   <div className="form-group">
//                     <label htmlFor="days">Days</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="days"
//                       value={days}
//                       onChange={handleChange}
//                       disabled={!editable}
//                     />
//                   </div>
//                 </div>
//                 <div className="box-body">
//                   <div className="form-group">
//                     <label htmlFor="timeInMinutes">Time In Minutes</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="timeInMinutes"
//                       value={timeInMinutes}
//                       onChange={handleChange}
//                       disabled={!editable}
//                     />
//                   </div>
//                 </div>
//               </div>
//               {editable ? (
//                 <>
//                   <button
//                     type="button"
//                     className="btn btn-primary"
//                     style={{ marginLeft: "20px" }}
//                     onClick={handleSaveClick}
//                   >
//                     Save
//                   </button>
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     style={{ marginLeft: "20px" }}
//                     onClick={handleCancelClick}
//                   >
//                     Cancel
//                   </button>
//                 </>
//               ) : (
//                 <button
//                   type="button"
//                   className="btn btn-primary"
//                   style={{ marginLeft: "20px" }}
//                   onClick={handleEditClick}
//                 >
//                   Edit
//                 </button>
//               )}
//             </form>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default LiveTime;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // corrected import statement
const baseUrl = process.env.REACT_APP_BASEURL;
const LiveTime = () => {
  const [editable, setEditable] = useState(false);
  const [days, setDays] = useState("");
  const [timeInMinutes, setTimeInMinutes] = useState("");
  const [id, setid] = useState("");
  useEffect(() => {
    // Fetch data from the backend API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/admin/livetime/getlivetime`
        );
        // Adjust the API endpoint as needed
        const responseData = response.data;
        setid(responseData.details._id);
        console.log(responseData);
        if (responseData.success) {
          // Update state with the fetched data
          setDays(responseData.details.days); // Assuming the days data is received as responseData.days
          setTimeInMinutes(responseData.details.time); // Assuming the timeInMinutes data is received as responseData.timeInMinutes
        } else {
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to only run this effect once on component mount

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    // Reset input values on cancel
    setDays("");
    setTimeInMinutes("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update state based on input field name
    if (name === "days") {
      setDays(value);
    } else if (name === "timeInMinutes") {
      setTimeInMinutes(value);
    }
  };
  const handleSaveClick = async () => {
    setEditable(false);

    try {
      const response = await axios.put(
        `${baseUrl}/admin/livetime/editlivetime?id=${id}`
      );
      const responseData = response.data;
      console.log(responseData);
      const data1 = responseData.data;
      console.log(data1);
      if (responseData.success) {
        // Assuming responseData contains updated data
        const updatedDays = data1.days;
        console.log(updatedDays);
        const updatedTime = data1.time;
        console.log(updatedTime);
        setDays(updatedDays); // Update days state
        setTimeInMinutes(updatedTime); // Update timeInMinutes state
      } else {
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   // Update state based on input field name
  //   if (name === "days") {
  //     setDays(value);
  //   } else if (name === "timeInMinutes") {
  //     setTimeInMinutes(value);
  //   }
  // };

  return (
    <>
      <section className="content-header">
        <h1>Manage Live Time</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageTag">Manage Live Time</Link>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Manage</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="days">Days</label>
                    <input
                      type="text"
                      className="form-control"
                      name="days"
                      value={days}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="timeInMinutes">Time In Minutes</label>
                    <input
                      type="text"
                      className="form-control"
                      name="timeInMinutes"
                      value={timeInMinutes}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              {editable ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginLeft: "20px" }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginLeft: "20px" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginLeft: "20px" }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveTime;
