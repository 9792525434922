import React, {useEffect ,useState} from "react";
import axios from "axios"

const BdDashboard = () => {
  const [fetchHost, setList] = useState({})
  const BaseUrl = process.env.REACT_APP_BASEURL1;

  const agency = sessionStorage.getItem("bd");
  
  const newAgency = JSON.parse(agency);
  let id = newAgency.uniqueId;

  const agencyadminlist = async ()=>{
    const response = await axios.get(`${BaseUrl}/agency/host/bdCounts?agadmin=${id} `)
    setList(response.data.details)
    console.log(response)
  }
  useEffect(() => {
    if (!sessionStorage.getItem("bd")) {
      window.location.href = "/bd";
    }
    agencyadminlist(); 
  }, [0]);

  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        {/* Info boxes */}
        <div className="row">
          {/* First Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Agency Admins</b>
                </span>
                <span className="info-box-number">{fetchHost?.admin || 0}</span>
              </div>
            </div>
          </div>
          {/* agency  */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Agencies</b>
                </span>
                <span className="info-box-number">{fetchHost?.agency || 0}</span>
              </div>
            </div>
          </div>
          {/* Second Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Approved Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.approved || 0}</span>
              </div>
            </div>
          </div>
          {/* Third Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Pending Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.pending || 0}</span>
              </div>
            </div>
          </div>
          {/* Fourth Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Rejected Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.rejected || 0}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BdDashboard;
