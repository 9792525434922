import React from "react";
import { Link } from "react-router-dom";

const ManageBadges = () => {
  const baseUrl = process.env.BASE_URL1;
  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Title</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/admin/dashboard">
              <i className="fa fa-dashboard" /> Home
            </a>
          </li>
          <li className="active">Title</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-header">
                <h3 className="box-title">
                  <a
                    href="/Badges/add"
                    style={{ fontSize: "14px" }}
                    className="btn btn-block btn-success btn-xs"
                  >
                    Add Badges
                  </a>
                </h3>
              </div>
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {/* Hard-coded data for demonstration */}
                    <tr>
                      <td>1</td>
                      <td>Sample Title</td>
                      <td>
                        <img
                          src={`${baseUrl}`}
                          style={{ width: "80px", height: "70px" }}
                        />
                      </td>
                      <td>
                        <ul
                          className="admin-action btn btn-default"
                          style={{
                            backgroundColor: "#f4f4f4",
                            color: "#fff !important",
                          }}
                        >
                          <li className="dropdown">
                            <a
                              className="dropdown-toggle"
                              style={{ color: "black" }}
                              data-toggle="dropdown"
                              href="#"
                              aria-expanded="false"
                            >
                              Action <span className="caret" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right">
                              <li role="presentation">
                                <Link role="menuitem" tabIndex={-1} to="">
                                  Edit
                                </Link>
                              </li>
                              <li role="presentation">
                                <Link role="menuitem" tabIndex={-1} to="">
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* Pagination section can be added here (if applicable) */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageBadges;
