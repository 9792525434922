import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';


const EditBanner = () => {
  
  const { fetchBannerr ,fetchBanner } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchBanner(userId);
    }, []);

  const navigate = useNavigate();
  const subbbb = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target); // Use e.target to access the form element
    $.ajax({
        url: `${process.env.REACT_APP_BASEURL}/admin/banner/updatebanner`,
        type: "post",
        dataType: "json",
        data: formData,
        contentType: false,
        processData: false,
        success: function(res) {
          if(res.success===1){
              toast.success(res.message)
              setTimeout(()=>{
                    navigate('/admin/manageBanner')
                    $('#form')[0].reset(); // Reset the form using jQuery
              },1000)
          }else{
              toast.error(res.message)
          }
        }
    });
}

  return (
    <>
        <section className="content-header">
          <h1>Edit Banner</h1>
          <ol className="breadcrumb">
            <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
            <li><Link to="/admin/manageBanner">Manage Banner</Link></li>
            <li className="active">Edit Banner</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <form role="form" method="post" id="form" onSubmit={subbbb} encType="multipart/form-data">
                <div className="box box-warning">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit</h3>
                  </div>
                  <div className="box-body">
                    <input type="hidden" name='id' defaultValue={fetchBannerr._id} />
                    <div className="form-group">
                      <label>Image*</label>
                        <input accept="image/*" type="file" className="form-control" name="image" id="file-ip-1" />
                    </div>
                    <div className="form-group">
                        <label>Old Icon*</label><br />
                        <img src={fetchBannerr.image} alt="" height={"80px"}/>
                    </div>
                    <div className="form-group">
                      <label>Country*</label>
                        <input type="text" className="form-control" name="country" placeholder="Country value" defaultValue={fetchBannerr.country} />
                      </div>
                    <div className="form-group">
                      <button type="reset" className="btn btn-danger">Cancel</button>
                      <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
    </>
  )
}

export default EditBanner