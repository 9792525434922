import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ManageAgencyAdmin = () => {
  const baseUrl = process.env.REACT_APP_BASEURL2;
  const [agencyDataDetails, setAgencyDataDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");

  // Fetch agency data from API
  const fetchAgencyInAgencyAdmin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL2}/agencyadmin/getAll`,
        { admin: agencyDetails.uniqueId, page, limit: entriesPerPage }
      );
      setAgencyDataDetails(response.data.details);
      setCount(response.data.count)
    } catch (error) {
      console.error("Error fetching agency data:", error.message);
    }
  };
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const debouncedSearch = debounce(async (searchValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL2}/agencyadmin/search?search=${searchValue}&admin=${agencyDetails.uniqueId}`
      );
      setAgencyDataDetails(response.data.details); // Update user state with search results
    } catch (error) {
      // toast.error(error.message);
    }
  }, 2000); // 2000ms debounce time

  // Handle search input change with debounce
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  // Getting details from session storage
  const agencyDetails = JSON.parse(sessionStorage.getItem("agencyadmin"));

  useEffect(() => {
    fetchAgencyInAgencyAdmin();
  }, [page, entriesPerPage]); // Fetch data when page or entriesPerPage changes

  // Handle pagination
  const handlePagination = (direction) => {
    if (direction === "prev" && page > 1) {
      setPage(page - 1);
    } else if (direction === "next") {
      setPage(page + 1);
    }
  };



  // Handle search form submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page when searching
    fetchAgencyInAgencyAdmin(); // Fetch data after resetting page
  };

  return (
    <>
      <section className="content-header">
        <h1>Manage Agency</h1>
       
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                <Link
                  to="/agencyadmin/addAgency"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add Agency
                </Link>
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <form onSubmit={handleSearchSubmit}>
                  <div className="row " style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-5 ">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="500">500</option>
                      </select>
                    </div>

                    <div
                      className="col-md-5"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <div
                        className="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                          Search
                        </span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                        />
                       
                      </div>
                    </div>
                  </div>
                </form>

                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Agency Code</th>
                      <th>Agency Name</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {agencyDataDetails.map((item, index) => (
                      <tr key={index}>
                        <td>{(page - 1) * entriesPerPage + index + 1}</td>
                        <td>
                          <img
                            src={`${baseUrl}${item.image}`}
                            alt=""
                            width="30px"
                            height="30px"
                          />
                        </td>
                        <td>{item.uniqueId}</td>
                        <td>{item.name}</td>
                        <td>{item.number}</td>
                        <td>
                          <ul
                            className="admin-action btn btn-default"
                            style={{
                              backgroundColor: "#f4f4f4",
                              color: "#fff !important",
                            }}
                          >
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                style={{ color: "black" }}
                                data-toggle="dropdown"
                                href="#"
                                aria-expanded="false"
                              >
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                <li role="presentation">
                                  <Link
                                    role="menuitem"
                                    tabIndex={-1}
                                    to={`/agencyadmin/viewAgency?id=${item._id}`}
                                  >
                                    View
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="row">
                  <div className="col-md-5">
                    <button
                      onClick={() => handlePagination("prev")}
                      disabled={page === 1}
                    >
                      Prev
                    </button>
                    <button
                      onClick={() => handlePagination("next")}
                      disabled={agencyDataDetails.length < entriesPerPage}
                    >
                      Next
                    </button>
                  </div>
                  <div className="col-md-5">
                    <div className="total-entries" style={{ float: "right", marginTop: "20px" }}>
                      Total entries: <b>{count}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageAgencyAdmin;
