import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import axios from "axios";
const EditLiveGift = () => {
  const { viewLiveGiftCategorys, viewLiveGiftCategory } = useContext(global);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    viewLiveGiftCategorys();
  }, []);

  const { fetchlivegif, fetchlivegifS } = useContext(global);
  console.log(fetchlivegifS.title);
  //   console.log(fetchlivegifS.title);
  //   const data = fetchlivegifS._id;
  //   console.log(data);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get("id");
    fetchlivegif(userId)
      .then(() => setLoading(false))
      .catch((error) => {
        console.error(error.message);
        toast.error("Failed to fetch gift data. Please try again later.");
      });

    $("#form")[0].reset(); // Reset the form using jQuery
    fetchlivegif(userId);
  }, []);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // id: data._id,
    title: "",
    image: "",
    coin: "",
    rank: null,
    giftimage: "",
    category: "",
  });
  useEffect(() => {
    if (fetchlivegifS) {
      setFormData({
        title: fetchlivegifS.title,
        image: fetchlivegifS?.image,
        coin: fetchlivegifS.coin,
        rank: fetchlivegifS.rank,
        giftimage: fetchlivegifS?.giftimage,
        category: fetchlivegifS.category,
      });
    }
  }, [fetchlivegifS]);
  const subbbb = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("id", fetchlivegifS?._id);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("coin", formData.coin);
    formDataToSend.append("rank", formData.rank);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("giftimage", formData.giftimage);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL4}/admin/livegifts/updatelivegifts`,
        formDataToSend
      );
      console.log(formDataToSend);
      if (response) {
        toast.success(response.data.message || "Gift added successfully!");
      } else {
        toast.error("Failed to add gift. Please try again later.");
      }
          navigate("/admin/manageLiveGift");

      // Clear form data if needed
      // setFormData({
      //   title: "",
      //   image: null,
      //   coin: "",
      //   giftimage: null,
      //   category: "",
      // });
    } catch (error) {
      console.error(error.message);
      toast.error("catchFailed to edit gift. Please try again later.");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const imageChange = (e) => {
    const { name, files } = e.target;
    console.log(files);
    const file = files[0];
    console.log(file);
    setFormData({ ...formData, [name]: file });
  };
  // $.ajax({
  //   url: `${process.env.REACT_APP_BASEURL}/admin/livegifts/updatelivegifts`,
  //   type: "post",
  //   dataType: "json",
  //   data: formData,
  //   contentType: false,
  //   processData: false,
  //   success: function (res) {
  //     if (res.success === 1) {
  //       toast.success(res.message);
  //       setTimeout(() => {
  //         navigate("/admin/manageLiveGift");
  //       }, 1000);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   },
  // });

  //////////////////////////

  /////////////////////////
  return (
    <>
      <section className="content-header">
        <h1>Edit LiveGift</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageLiveGift">Manage LiveGift</Link>
          </li>
          <li className="active">Edit LiveGift</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              id="form"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input
                    type="hidden"
                    name="id"
                    defaultValue={fetchlivegifS._id}
                  />
                  <div className="form-group">
                    <label>Category List*</label>
                    <select
                      type="text"
                      className="form-control"
                      name="category"
                      //   onChange={(e) => {
                      //     $(".form-error1").html("");
                      //   }}
                      onChange={handleChange}
                    >
                      <option value="">Select Gift Category</option>
                      {Array.isArray(viewLiveGiftCategory) &&
                        viewLiveGiftCategory.map((item, index) => {
                          let i = index + 1;
                          return (
                            <>
                              <option
                                value={item._id}
                                selected={fetchlivegifS.category === item._id}
                              >
                                {item.title}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      defaultValue={fetchlivegifS.title}
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Coin*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coin"
                      placeholder="Coin"
                      defaultValue={fetchlivegifS.coin}
                      value={formData.coin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Rank*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="rank"
                      placeholder="Rank"
                      defaultValue={fetchlivegifS.rank}
                      value={formData.rank}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Image*</label>
                    <input
                      accept="image/*"
                      type="file"
                      className="form-control"
                      name="image"
                      id="file-ip-1"
                      // defaultValue={fetchlivegifS?.image}
                      onChange={imageChange}
                    />
                  </div>
                  <div className="form-group">
                    <label> Gift Image*</label>
                    <input
                      accept="giftimage/*"
                      type="file"
                      className="form-control"
                      name="giftimage"
                      // defaultValue={fetchlivegifS?.giftimage}
                      onChange={imageChange}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />
                    <img src={fetchlivegifS?.image} alt="" height={"80px"} />
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditLiveGift;
