import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";


const SendCoinHistory = () => {
  const [rechargeHistory, setRechargeHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const rechargeHistorys = async (value) => {
    try {
      setLoading(true)

      const { page, limit, ...otherParams } = value; // Destructure page and limit from value

      let data = await fetch(
        `${process.env.REACT_APP_BASEURL3}/coin/recharge/history?page=${page}&limit=${limit}`, // Include page and limit in URL
        {
          method: "POST",
          body: JSON.stringify(otherParams),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let response = await data.json();

      setRechargeHistory(response.history);
      setTotalItems(response.total); // Assuming response contains total count
      setLoading(false)

    } catch (err) {
      toast.error(err.message);
    }
  };
  const rechargeHistoryssearch = async (value) => {
    try {
      setLoading(true)
      
      let data = await fetch(
        `${process.env.REACT_APP_BASEURL1}/coin/recharge/searchsendCoinHistory?userId=${coinDetails.uniqueId}&search=${value}`, // Include page and limit in URL
        {
          method: "GET",
        }
      );
      let response = await data.json();

      setRechargeHistory(response.history);
      setTotalItems(response.total); // Assuming response contains total count
      setLoading(false)

    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      if (value !== "")
        rechargeHistoryssearch(value);
      else {
        rechargeHistorys({userId: coinDetails.uniqueId, page: currentPage, limit: itemsPerPage });
      }
    }, 1000),
    [currentPage]
  );
  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);

  useEffect(() => {
    if (!sessionStorage.getItem("coin")) {
      window.location.href = "/coin";
    } else {
      rechargeHistorys({userId: coinDetails.uniqueId, page: currentPage, limit: itemsPerPage});
    }
  }, [currentPage, itemsPerPage]); // Depend on currentPage and itemsPerPage

  // Pagination logic
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Calculate current items to display based on currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 

  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Coin History</h1>
        <ol className="breadcrumb">
        </ol>
      </section>
      {/* Main content */}
      <section className="content row">
        {/* Pagination options at the top */}
        <div className="pagination-options col-xs-6">
          <select
            className="form-control pagination-select"
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
          >
            <option value="10">10 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
            <option value="1000">1000 per page</option>
          </select>
        </div>
        <div className="main-data-single-field col-xs-6" style={{ width: "fit-content" }}>
                        <span style={{ fontWeight: "bold", marginRight: "8px" }}>Search</span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{ borderRadius: "4px", borderStyle: "groove", width: "150px" }}
                          onChange={(e) => handleSearchChange(e.target.value)}
                          placeholder="Search User"
                        />
                      </div>
        

        <div className="row">
       
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>User</th>
                      <th>Coin</th>
                      <th>Date/Time</th>
                    </tr>
                  </thead>
                  {loading?<>Loading...</>:<tbody>
                    {Array.isArray(rechargeHistory) &&
                      rechargeHistory.map((item, index) => {
                        let i = index + indexOfFirstItem + 1; // Calculate actual index
                        return (
                          <tr key={index}>
                            <td>{i}</td>
                            <td>{item?.userId}</td>
                            <td>
                              <label className="label label-success">
                                {item?.coin}
                              </label>
                            </td>
                            <td>{new Date(item?.createdAt).toLocaleString()}</td>
                          </tr>
                        );
                      })}
                  </tbody>}
                </table>
                {/* Pagination controls */}
                <div className="pagination-container">
                  <button
                    className="btn btn-default"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                 
                  <button
                    className="btn btn-default"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <div className="">
                    <div className="total-entries" style={{ float: "right", marginTop: "20px" }}>
                      Total entries: <b>{totalItems}</b>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default SendCoinHistory;
