import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ReturnCoinHistory = () => {
  const [received, setReceived] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items

  const rechargeRecievedHistorys = async (value) => {
    try {
      const { page, limit, ...otherParams } = value; // Destructure page and limit from value

      let data = await fetch(
        `${process.env.REACT_APP_BASEURL3}/coin/recharge/returnhistory?page=${page}&limit=${limit}&coin=${(otherParams.userId)}`, // Include page and limit in URL
        {
          method: "GET",
        }
      );
      let response = await data.json();

      setReceived(response.details);
      setTotalItems(response.total); // Assuming response contains total count
    } catch (err) {
      toast.error(err.message);
    }
  };

  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);

  useEffect(() => {
    if (!sessionStorage.getItem("coin")) {
      window.location.href = "/coin";
    } else {
      rechargeRecievedHistorys({ userId: coinDetails.uniqueId, page: currentPage, limit: itemsPerPage });
    }
  }, [currentPage, itemsPerPage]); // Depend on currentPage and itemsPerPage

  // Pagination logic
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Calculate current items to display based on currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Return Coin History</h1>
      
      </section>
      {/* Main content */}
      <section className="content">
        {/* Pagination options at the top */}
        <div className="pagination-options">
          <select
            className="form-control pagination-select"
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
          >
            <option value="10">10 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
            <option value="500">500 per page</option>
            <option value="1000">1000 per page</option>
          </select>
        </div>

        <div className="row">
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>User</th>
                      <th>Coin</th>
                      <th>Return Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(received) &&
                      received.map((item, index) => {
                        let i = index + indexOfFirstItem + 1; // Calculate actual index
                        return (
                          <tr key={index}>
                            <td>{i}</td>
                            <td>{item?.userId?.username}</td>
                            <td>
                              <label className="label label-success">
                                {item.coins}
                              </label>
                            </td>
                            <td>{new Date(item?.createdAt).toLocaleString()}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* Pagination controls */}
                <div className="pagination-container">
                  <button
                    className="btn btn-default"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  
                  <button
                    className="btn btn-default"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default ReturnCoinHistory;
