import React from 'react'
import "../Css/Footer.css"


const Footer = () => {
  return (
    <>
        <footer className="main-footer">
            <div className="pull-right hidden-xs">
                <b>Version</b> 2.4.12
            </div>
            <strong>Copyright &copy; 2024-2025 <a target="_blank" href="">project</a>.</strong> All rights
            reserved.
        </footer>
    </>
  )
}

export default Footer