import React, { useContext, useEffect, useState } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import { global } from "../../Context/Context";
import { value } from "../../config/data";
import axios from "axios";

const BdHostLists = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [loading,setLoading] = useState(false)
  let [searchParams] = useSearchParams();

const adminData = searchParams.get('agency');
const bd = searchParams.get('bd');
const admin = searchParams.get('agencyadmin');
const startDate = searchParams.get('start');
const endDate = searchParams.get('end');
  //Get Bds lists 
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/withdrawal/With?start=${startDate}&end=${endDate}&agency=${adminData}&agencyadmin=${admin}&bd=${bd}`)
    // const {data} = await axios.get(`https://mastishortss.com/video/admin/withdrawal/data?start=${startDate}&end=${endDate}&agency=${adminData}`)
   
    // console.log(data)
    if(data.success == '1') {
setLoading(false)
      setWithdrawalLists(data.details)
    }
 } catch (error) {
    console.log(error.message)
 }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, [startDate,endDate]);


  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  // const currentUsers = user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers =
  //   searchData.length > 0
  //     ? searchData.slice(indexOfFirstUser, indexOfLastUser)
  //     : user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers = widthdrawalList.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  // const totalPages = Math.ceil(user.length / entriesPerPage);
  const totalPages = Math.ceil(
    user?.length / entriesPerPage
  );

//   const searhUser = async () => {
//     try {
//     //   setSearchData(user);
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_BASEURL1}/admin/search?search=${search}`
//       );
//       // console.log(data);
//     //   setSearchData(data);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };


  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Bd Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>
                   

                  </div>
                </div>
              </form>
              <div className="box-body">
                {/* <!-- Content here --> */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    <th>Sr.</th>
                      <th>Image</th>
                      <th>Host Username</th>
                      <th>W. coins</th>
                      <th>USD Amount</th>
                      <th>Target USD Amount</th>
                      <th>Total</th>
                      <th>INR</th>
                      <th>Agency Commission(10%)</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" style={{width:"100%",margin:"0 auto"}}>Loading...</h6> </td> : Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        let inr = ((+(item?.coins/value.USD)?.toFixed(2) + +(item?.bonus?.targetCommission))*value.USDINR)?.toFixed(2);
                        let commission = (inr*value.agencyComm)?.toFixed(2);
                        return (
                          <tr key={index}>
                            <td>{i}</td>
                            <td><img src={`${baseUrl}${item?.picture}`} height={40} width={40} style={{borderRadius:"100%"}}/></td>
                         
                            <td>{item?.username}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.dolor}</td>
                            <td>{item?.targetCommission}</td>
                            <td>{item?.totalCommission}</td>
                            <td>{(item?.totalCommission)*value.USDINR}</td>
                            <td>{(((item?.totalCommission)*value.USDINR)*value.agencyComm).toFixed(2)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
               
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default BdHostLists;
