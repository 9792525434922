import React from 'react'

const AddBadges = () => {
    return (
        <>
            <section className="content-header">
                <h1>Title</h1>
                <ol className="breadcrumb">
                    <li><a href="/admin/dashboard"><i className="fa fa-dashboard" /> Home</a></li>
                    <li><a href="/Banners">View Banner</a></li>
                    <li className="active">Title</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" id="specialitiesForm" encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Title</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="title" defaultValue="Title" />
                                    </div>
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input accept="image/*" type="file" className="form-control" name="image" id="file-ip-1" />
                                        <img id="file-ip-1-preview" name="pre" src="#" alt="your image" height={70} width={70} style={{ display: 'none' }} />
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddBadges