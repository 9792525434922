import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const AgencAdminView = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("id");

  const [agencyadmindata, setAgencyAdmindata] = useState([]);
  const [agencyDataDetails, setAgencyDataDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const edit = async (id) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/agencadminpanel/getone?id=${id}`
      );
      setAgencyAdmindata(data.data);
      toast.success(data.message);
    } catch (error) {
      console.error("Error edit /agencadmin:", error);
      toast.error("Failed to edit /agencadmin");
    }
  };

  useEffect(() => {
    if (userId) {
      edit(userId);
    }
  }, [userId]);

  const InAgencyAdmin = async (page = 1, limit = 5) => {
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL1}/agencyadmin/allagency`,
        { agencyadmin: agencyadmindata.uniqueId },
        {
          params: { page, limit },
        }
      );
      setAgencyDataDetails(data.details);
      setTotalPages(Math.ceil(data.total / limit)); // Assuming `data.total` holds the total count of records
    } catch (error) {
      console.error("Error fetching agency data:", error);
    }
  };

  useEffect(() => {
    if (agencyadmindata.uniqueId) {
      InAgencyAdmin(currentPage, entriesPerPage);
    }
  }, [agencyadmindata, currentPage, entriesPerPage]);

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle limit change
  const handleLimitChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever the limit changes
  };

  return (
    <>
      <section className="content-header">
        <h1>View Agency Admin</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/bd/viewagencyadmin">Manage Agency Admin</Link>
          </li>
          <li className="active">View Agency Admin</li>
        </ol>
      </section>

      <section className="content">
        <div className="row" style={{ display: "flex", justifyContent: "center" }}>
          <div className="col-md-10 col-12">
            {/* Agency Admin Info */}
            <div className="box box-warning">
              <div className="box-header with-border" style={{ display: "flex" }}>
                <div style={{ position: "relative" }}>
                  <img
                    src={`${baseUrl}${agencyadmindata.image}`}
                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                    alt="Agency Admin"
                  />
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <span className="label label-warning" style={{ fontSize: "10px" }}>
                    Agency Admin
                  </span>
                  <div style={{ fontSize: "20px" }}>{agencyadmindata?.name}</div>
                </div>
              </div>

              {/* Agency Admin Details */}
              <div className="box-body">
                <div className="form-group">
                  <label>Agency Admin name*</label>
                  <input type="text" className="form-control" value={agencyadmindata?.name} readOnly />
                </div>
                <div className="form-group">
                  <label>Agency Admin Code*</label>
                  <input type="text" className="form-control" value={agencyadmindata?.uniqueId} readOnly />
                </div>
                <div className="form-group">
                  <label>Number*</label>
                  <input type="text" className="form-control" value={agencyadmindata?.number} readOnly />
                </div>
              </div>
            </div>

            {/* Pagination Controls */}
            <div className="form-group">
              <label>Entries per page:</label>
              <select value={entriesPerPage} onChange={handleLimitChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>

            {/* Agency Admin Table */}
            <table id="example1" className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Image</th>
                  <th>Agency Code</th>
                  <th>Agency Name</th>
                  <th>Phone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody id="ts">
                {Array.isArray(agencyDataDetails) &&
                  agencyDataDetails.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * entriesPerPage + index + 1}</td> {/* Corrected serial number */}
                      <td>
                        <img src={`${baseUrl}${item.image}`} alt="" width={"40px"} height={"40px"} />
                      </td>
                      <td>{item.uniqueId}</td>
                      <td>{item.name}</td>
                      <td>{item.number}</td>
                      <td>
                        <Link to={`/admin/viewAgency?id=${item._id}`}>View</Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* Pagination */}
            <nav aria-label="Page navigation" className="pagination-nav">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Prev
                  </button>
                </li>
                <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencAdminView;
