import React, { useState, useEffect } from "react";
import axios from "axios";

const ManageSeniorAgency = () => {
  const [bdData, setBdData] = useState(null);
  const [agencyAdminData, setAgencyAdminData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch senior data from API
  const agencyDetails = JSON.parse(sessionStorage.getItem("agency"));

  const fetchSeniorData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/agency/host/findSeniors?uniqueId=${agencyDetails.uniqueId}`
      );
      if (response.data.success === "1") {
        setBdData(response.data.details.bd);
        setAgencyAdminData(response.data.details.agencyAdmin);
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching senior data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeniorData();
  }, []);

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger mt-5">{error}</div>;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">BD Details</h2>
      {bdData && (
        <div className="card shadow-sm mb-4">
          <div className="card-header bg-primary text-white text-center">
            <h3 className="card-title mb-0">{bdData.name}</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 text-center">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${bdData.image}`}
                  alt={bdData.name}
                  className="img-fluid rounded-circle border border-secondary"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                />
              </div>
              <div className="col-md-8">
                <p className="lead">
                  <strong>Email:</strong> {bdData.email}
                </p>
                <p>
                  <strong>Unique ID:</strong>{" "}
                  <span className="badge bg-secondary">{bdData.uniqueId}</span>
                </p>
                <p>
                  <strong>Phone Number:</strong> {bdData.number || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <hr/>
        <h2 className="text-center mb-4">Agency Admin Details</h2>

      {agencyAdminData && (
        <div className="card shadow-sm mb-4">
          <div className="card-header bg-success text-white text-center">
            <h3 className="card-title mb-0">{agencyAdminData.name}</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 text-center">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${agencyAdminData.image}`}
                  alt={agencyAdminData.name}
                  className="img-fluid rounded-circle border border-secondary"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                />
              </div>
              <div className="col-md-8">
                <p className="lead">
                  <strong>Email:</strong> {agencyAdminData.email}
                </p>
                <p>
                  <strong>Unique ID:</strong>{" "}
                  <span className="badge bg-secondary">
                    {agencyAdminData.uniqueId}
                  </span>
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {agencyAdminData.number || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSeniorAgency;
