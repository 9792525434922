import React, { useEffect, useState } from "react";
import axios from "axios";
import { value } from '../../config/data'

const AgencyAdminDashboard = () => {
  const [fetchHost, setList] = useState({});
  const [withdrawalData, setWithdrawalData] = useState(null);
  const [widthdrawalList, setWithdrawalLists] = useState(0)
  const [loading, setLoading] = useState(true)

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const BaseUrl = process.env.REACT_APP_BASEURL1;
  const agency = sessionStorage.getItem("agencyadmin");
  const newAgency = JSON.parse(agency);
  let id = newAgency.uniqueId;

  const agencyadminlist = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${BaseUrl}/agency/host/agencyAdminCounts?agadmin=${id}`
      );
      setList(response.data.details);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching agency admin list:", error);
    }
  };

  const getBdLists = async () => {
    try {
      setLoading(true)
      const today = new Date();
      const currentYear = today.getFullYear();
      const firstDayObj = new Date(currentYear, selectedMonth - 1, 1);
      const firstYear = firstDayObj.getFullYear();
      const firstMonth = String(firstDayObj.getMonth() + 1).padStart(2, '0');
      const firstDay = String(firstDayObj.getDate()).padStart(2, '0');
      const firstFormattedDate = `${firstYear}-${firstMonth}-${firstDay}`;

      let lastDayObj;
      if (selectedMonth === today.getMonth() + 1) {
        lastDayObj = today;
      } else {
        lastDayObj = new Date(currentYear, selectedMonth, 0);
      }
      const lastYear = lastDayObj.getFullYear();
      const lastMonth = String(lastDayObj.getMonth() + 1).padStart(2, '0');
      const lastDay = String(lastDayObj.getDate()).padStart(2, '0');
      const lastFormattedDate = `${lastYear}-${lastMonth}-${lastDay}`;

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL2}/admin/withdrawal/agency?start=${firstFormattedDate}&end=${lastFormattedDate}&admin=${id}`
      );

      if (data.success == '1') {
        const totolSumOfCommission = data.details.reduce((acc, total) => {
          const convertedAmount = total.totalINR + total.totalINR * value.agencyComm;
          return acc + convertedAmount;
        }, 0);
        setWithdrawalLists(totolSumOfCommission.toFixed(2));
        console.log(totolSumOfCommission)
      }
      setLoading(false)

    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchWithdrawalData = async (month) => {
    try {
      setLoading(true)

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/getForCreatedFor?uniqueId=${id}&month=${month}`
      );
      if (response.data.success === "1") {
        setWithdrawalData(response.data.details);
      } else {
        setWithdrawalData(null);
      }
      setLoading(false)
    } catch (error) {
      console.error("Error fetching withdrawal data:", error);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem("agencyadmin")) {
      window.location.href = "/agencyadmin";
    }
    getBdLists();
    fetchWithdrawalData(selectedMonth); // Fetch data for the current month on mount
  }, [selectedMonth]);
  useEffect(() => {
    if (!sessionStorage.getItem("agencyadmin")) {
      window.location.href = "/agencyadmin";
    }
    agencyadminlist();
  }, []);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

 
  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
     {loading?"Loading": <section className="content">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Agencies</b>
                </span>
                <span className="info-box-number">{fetchHost?.agency || 0}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Approved Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.approved || 0}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Pending Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.pending || 0}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Rejected Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.rejected || 0}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h3>Target Withdrawal</h3>
            <div className="form-group">
              <label htmlFor="monthSelect">Select Month:</label>
              <select
                id="monthSelect"
                className="form-control"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString("default", {
                      month: "long",
                    })}
                  </option>
                ))}
              </select>
            </div>
            {withdrawalData ? (
              <div className="info-box" style={{ color: "#000000", padding: "20px" }}>
                <p><strong>Month:</strong> {new Date().toLocaleString('default', { month: 'long' })}</p>
                <p>
                  <strong>Target Amount:</strong> {withdrawalData.amount}
                </p>
                <p>
                  <strong>Your Withdrawal:</strong> {widthdrawalList}
                </p>
                <p>
                  <strong>Created By:</strong> {withdrawalData.createdBy || "Not created"}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(withdrawalData.createdAt).toLocaleString()}
                </p>
                <p>
                  <strong>Last Edited:</strong> {new Date(withdrawalData.updatedAt).toLocaleString()}
                </p>
              </div>
            ) : (
              <div className="info-box" style={{ color: "#000000", padding: "20px" }}>
                <p><strong>Month:</strong> {new Date().toLocaleString('default', { month: 'long' })}</p>
                <p><strong>Target Amount:</strong> 0</p>
                <p><strong>Your Withdrawal:</strong> {widthdrawalList}</p>
                <p><strong>Created By:</strong> Not created</p>
              </div>
            )}
          </div>
        </div>
      </section>}
    </>
  );
};

export default AgencyAdminDashboard;
