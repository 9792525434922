import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import _ from "lodash";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchPayments();
  }, [entriesPerPage, page, paymentStatus, verificationStatus]);

  const renderSerialNumber = (index) => {
    return (page - 1) * entriesPerPage + index + 1;
  };

  const fetchPayments = async () => {
    try {
      setPayments([]);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL5}/admin/withdrawal/plansPayments`,
        {
          params: {
            page,
            limit: entriesPerPage,
            paymentStatus,
            verificationStatus,
          },
        }
      );

      if (response.data) {
        setPayments(response.data.details);
        setCount(response.data.total);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value);
      if (value !== "") {
        searchPayments(value);
      } else {
        fetchPayments();
      }
    }, 0),
    []
  );

  const searchPayments = async (query) => {
    try {
      setPayments([]);
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL5}/admin/withdrawal/plansPaymentsSearch`,
        {
          params: {
            search: query,
            page,
            limit: entriesPerPage,
            paymentStatus,
            verificationStatus,
          },
        }
      );
      setPayments(response.data.details);
      setCount(response.data.total);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSettlePayment = async (orderId) => {
    const confirmSettle = window.confirm(
      "Are you sure you want to settle this payment?"
    );

    if (confirmSettle) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL5}/admin/withdrawal/settlePayment`,
          { orderId }
        );
        if (response.data.success) {
          alert("Payment settled successfully");
          fetchPayments(); // Refresh the payment list
        } else {
          alert(`Failed to settle payment: ${response.data.message}`);
        }
      } catch (error) {
        console.error(error.message);
        alert("Error while settling payment.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Payments</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div
                    className="form-group col-xs-4 "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                    <span>
                      <button
                        type="button"
                        style={{
                          padding: ".5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSearchChange(searchValue);
                        }}
                      >
                        Go
                      </button>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Payment Status</label>
                    <select
                      className="form-control"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="1">Paid</option>
                      <option value="0">Unpaid</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Verification Status</label>
                    <select
                      className="form-control"
                      value={verificationStatus}
                      onChange={(e) => setVerificationStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="1">Verified</option>
                      <option value="0">Pending</option>
                      <option value="2">Failed</option>
                    </select>
                  </div>
                </div>

                <table
                  className="table table-bordered table-striped"
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Number</th>
                      <th>Plan Rs</th>
                      <th>Coins</th>
                      <th>RazorPay Amount (Paisa)</th>
                      <th>Order ID</th>
                      <th>Payment Status</th>
                      <th>Verification Status</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.length === 0
                      ? "Loading..."
                      : payments.map((payment, i) => (
                          <tr key={payment._id}>
                            <td>{renderSerialNumber(i)}</td>
                            <td>{payment.user?.username}</td>
                            <td>{payment.user?.number}</td>
                            <td>{payment.plan?.rs}</td>
                            <td>{payment.plan?.coins}</td>
                            <td>{payment.amount}</td>
                            <td>{payment.orderId}</td>
                            <td colSpan="2">
                              {payment.settled == 1 ? (
                                <span className="label label-warning">
                                  Settled
                                </span>
                              ) : (
                                <>
                                  <span
                                    className={`label ${
                                      payment.paymentStatus == 0
                                        ? "label-danger"
                                        : "label-success"
                                    }`}
                                  >
                                    {payment.paymentStatus == 0
                                      ? "Unpaid"
                                      : "Paid"}
                                  </span>
                                  <span
                                    className={`label ${
                                      payment.verificationStatus == 0
                                        ? "label-warning"
                                        : payment.verificationStatus == 1
                                        ? "label-success"
                                        : "label-danger"
                                    }`}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {payment.verificationStatus == 0
                                      ? "Pending"
                                      : payment.verificationStatus == 1
                                      ? "Verified"
                                      : "Failed"}
                                  </span>
                                </>
                              )}
                            </td>

                            <td>
                              {new Date(payment.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  handleSettlePayment(payment.orderId)
                                }
                                className="btn btn-primary"
                                disabled={loading || payment.settled == 1}
                              >
                                {payment.settled == 1
                                  ? "Settled"
                                  : "Settle Payment"}
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>

                <div className="pagination">
                  <div>Total Entries: {count}</div>
                  <ul className="pagination">
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                    </li>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) =>
                            Math.ceil(count / entriesPerPage) > page
                              ? prev + 1
                              : prev
                          )
                        }
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payments;
