import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";

const FrameLevel = () => {
  const { level, viewlevel, getUserLevel, userLevel } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //   console.log(userLevel);
  const [de, setDe] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}/admin/frame/framelevel`
        );

        setData(response.data.details);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
    viewlevel();
    getUserLevel();
  }, [de]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  // const currentUsers = level.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math.ceil(level.length / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <section className="content-header">
        <h1>Frame Level Wallpapers</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Level</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-header">
                <h3 className="box-title">
                  <Link
                    to="/admin/addframelevel"
                    style={{ fontSize: "14px" }}
                    className="btn btn-block btn-success btn-xs"
                  >
                    Add More Frame Level
                  </Link>
                </h3>
              </div>
              {/* Box Body */}
              <div className="box-body" style={{ padding: 0 }}>
                {/* <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div> */}

                {/* Table */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                  style={{ marginBottom: 0 }}
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Sr.</th>
                      <th style={{ textAlign: "center" }}>Level</th>
                      <th style={{ textAlign: "center" }}>Image</th>
                      {/* <th style={{ textAlign: "center" }}>Experience</th> */}
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {Array.isArray(data) &&
                      data.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr className="text-center">
                            <td>{i}</td>
                            <td>{item.level}</td>
                            <td>
                              <img
                                src={`${process.env.REACT_APP_BASEURL}${item.image}`}
                                alt="Image"
                                height="100px"
                                width="100px"
                              />
                            </td>
                            {/* <td>{item.expTo}</td> */}
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li class="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "level",
                                            `${item?.level} `
                                          );
                                          sessionStorage.setItem(
                                            "image",
                                            `${item?.image} `
                                          );
                                        }}
                                        to={`/admin/updateframelevel?id=${item?._id}`}
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to delete this item?"
                                          );
                                          if (confirmDelete) {
                                            $.ajax({
                                              url: `${process.env.REACT_APP_BASEURL}/admin/frame/framelevel?id=${item._id}`,
                                              type: "delete",
                                              dataType: "json",
                                              success: function (res) {
                                                if (res.succes === 1) {
                                                  toast.success(res.message);
                                                  setDe(1);
                                                } else {
                                                  toast.error(res.message);
                                                }
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                    {/* Table Body Content */}
                  </tbody>
                </table>
                {/* Pagination */}
                <div className="row">
                  {/* Pagination */}

                  {/* <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{level.length}</b>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FrameLevel;
