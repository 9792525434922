import React, { useContext, useEffect, useState } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import { global } from "../../Context/Context";
import {value} from '../../config/data'
import axios from "axios";
import { toast } from "react-toastify";

const AgencyRanking = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)
  const [loading,setLoading] = useState(false)
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  let [searchParams] = useSearchParams();


  const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = (total.totalINR );
    return acc + convertedAmount;
  }, 0);
  const totolSumOfCommission = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = (total.totalINR )*value.agencyComm;
    return acc + convertedAmount;
  }, 0);



  

  const currentDate = new Date();

  // Calculate the date two days before the current date
  const startDateObj = new Date(currentDate);
  startDateObj.setDate(currentDate.getDate() - 7);
  
  // Calculate the date one day before the current date
  const endDateObj = new Date(currentDate);
  endDateObj.setDate(currentDate.getDate());
  
  // Format the start date to YYYY-MM-DD
  const startYear = startDateObj.getFullYear();
  const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDateObj.getDate()).padStart(2, '0');
  const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
  
  // Format the end date to YYYY-MM-DD
  const endYear = endDateObj.getFullYear();
  const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDateObj.getDate()).padStart(2, '0');
  const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
  

  // if (startDate === null) {
  //   setStartDate(startFormattedDate);
  // }
  if (endDate === null) {
    setEndDate(endFormattedDate);
  } 

  // console.log(startDate)
  let currentDate1 = new Date();
  let currentDay1 = currentDate1.getDay();
  let prevMonday = new Date(currentDate1);
  prevMonday.setDate(currentDate.getDate() - ((currentDay1 + 6) % 7));
  
let lastMonday = startDate !== null ? startDate : prevMonday.toISOString().split("T")[0];


const getData = sessionStorage.getItem("agencyadmin");
const getDataParse = JSON.parse(getData)
// console.log(getDataParse)

  //Get Bds lists 
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`https://mastishortss.com/version2/admin/withdrawal/agencyRanking?start=${lastMonday}&end=${endDate}`)
   
    // console.log(data)
    if(data.success =='1') {
      // console.log(data.details)
    setLoading(false)
      setWithdrawalLists(data.details)
      toast.success(data.message)
      
    }
 } catch (error) {
    console.log(error.message)
 }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, [lastMonday,endDate,startDate]);

 
  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Agency Ranking</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>
                   

                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                         Start Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setStartDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          End Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            setEndDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="box-body">
              <div style={{border:"1px solid white",display:"flex",gap:"2rem"}}>
                  <div className="" >
                    <span style={{fontWeight:"bold"}}>From :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{lastMonday}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <span style={{fontWeight:"bold"}}>To :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{endDate}</span>
                  </div>
                </div>
                {/* <!-- Content here --> */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    <th>Rank</th>
                    {/* <th>Select</th> */}
                      <th>Image</th>
                      <th>Agency Id</th>
                      <th>Agency Name</th>
                      <th>BD Name</th>
                        <th>Admin name</th>
                      <th>Total Withdrawal Coin</th>
                   
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" style={{width:"100%",margin:"0 auto"}}>Loading...</h6> </td> : Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = index + 1;
                        return (
                          <tr key={index}>
                            <td>{i}</td>
                      
                            <td>
                              <img
                                src={`${item?.image}`}
                                style={{ width: "30px",height:"30px",borderRadius:"50%" }}
                                alt="user"
                              />
                            </td>
                            <td>{item?.uniqueId}</td>
                            <td>{item?.name}</td>
                             <td>{item?.bd}</td>
                             <td>{item?.admin}</td>
                            <td>{item?.totalCoins}</td>

                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
// Define active style object
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default AgencyRanking;
