import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";

const EditLevel = () => {
  const { fetchlevel, fetchlevell } = useContext(global);
  console.log(fetchlevel);
  console.log("eorieeo");
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get("id");

    $("#form")[0].reset(); // Reset the form using jQuery
    fetchlevel(userId);
  }, []);

  const navigate = useNavigate();

  const subbbb = (e) => {
    e.preventDefault();

    let formData = new FormData(e.target);

    $.ajax({
      url: `${process.env.REACT_APP_BASEURL}/admin/level/updatelevel`,
      type: "post",
      dataType: "json",
      data: formData,
      processData: false, // Add this line
      contentType: false, // Add this line
      success: function (res) {
        if (res.success === 1) {
          toast.success(res.message);
          setTimeout(() => {
            navigate("/admin/manageLevel");
          }, 1000);
        } else {
          toast.error(res.message);
        }
      },
    });
  };

  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: "20px" }}>Edit Level</h1>
        <ol className="breadcrumb" style={{ marginBottom: "20px" }}>
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageLevel">Manage Level</Link>
          </li>
          <li className="active">Edit Level</li>
        </ol>
      </section>
      <section className="content" style={{ padding: "0 15px" }}>
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              id="form"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input type="hidden" name="id" value={fetchlevell._id} />
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Level*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="level"
                      placeholder="level eg.(1 , 2 , 3...)"
                      style={{ width: "100%" }}
                      defaultValue={fetchlevell?.level}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Experience*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="expTo"
                      placeholder="ExpTo"
                      style={{ width: "100%" }}
                      defaultValue={fetchlevell.expTo}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Icon*</label>
                    <input
                      accept="image/*"
                      type="file"
                      className="form-control"
                      name="icon"
                      style={{ width: "100%" }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />
                    <img src={fetchlevell.icon} alt="" height={"80px"} />
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <button
                      type="reset"
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditLevel;
