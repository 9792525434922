import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const RankingComponent = () => {
    const [rankings, setRankings] = useState([]);
    const [selectedType, setSelectedType] = useState(0); // 0 for sending, 1 for receiving
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default to current month
    const [loading, setLoading] = useState(false);

    const baseUrl = process.env.REACT_APP_BASEURL4;

    const fetchRankings = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${baseUrl}/admin/withdrawal/sendingRecievingRanking`, // Update with the actual API path
                {
                    params: {
                        type: selectedType,
                        month: selectedMonth,
                    },
                }
            );
            setRankings(response.data.details || []);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error('Failed to fetch rankings');
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRankings();
    }, [selectedType, selectedMonth]);

    const handleTypeChange = (e) => {
        setSelectedType(parseInt(e.target.value));
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value));
    };

    return (
        <div className='content'>
            <div className="box-header with-border">
                <h3 className="box-title">Ranking</h3>
            </div>
            <div className='row'>

                <div className="form-group col-md-6">
                    <label htmlFor="selectType">Select Type:</label>
                    <select
                        id="selectType"
                        className="form-control"
                        value={selectedType}
                        onChange={handleTypeChange}
                    >
                        <option value={0}>Sending</option>
                        <option value={1}>Receiving</option>
                    </select>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="selectMonth">Select Month:</label>
                    <select
                        id="selectMonth"
                        className="form-control"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                    >
                        <option value={0}>January</option>
                        <option value={1}>February</option>
                        <option value={2}>March</option>
                        <option value={3}>April</option>
                        <option value={4}>May</option>
                        <option value={5}>June</option>
                        <option value={6}>July</option>
                        <option value={7}>August</option>
                        <option value={8}>September</option>
                        <option value={9}>October</option>
                        <option value={10}>November</option>
                        <option value={11}>December</option>
                    </select>
                </div>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Username</th>
                            <th>Total Diamonds</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankings.map((rank, index) => (
                            <tr key={rank.userId}>
                                <td>{index + 1}</td>
                                <td>{rank.userId.username}</td>
                                <td>{rank.totalDiamondsMonthly}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default RankingComponent;
