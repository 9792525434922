import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const ViewBdData = () => {
  const [bddata, setBddata] = useState([]);
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("id");
  const [agencyadimindata, setAgencyAdminData] = useState([]);
///////////

//////////
  const edit = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/bdpanel/getone?id=${id}`);
      setBddata(data.data);
      toast.success(data.message);
    } catch (error) {
      console.error("Error edit BD:", error);
      toast.error("Failed to edit BD");
    }
  };

  useEffect(() => {
    if (userId) {
      edit(userId);
    
    }
  }, [userId]);
//  setTimeout(() => {
  const manageagencadminApi = async () => {
    try {
      const agencyAdminResponse = await axios.post(
        `${baseUrl}/agencadminpanel/getagencyadmin`,
        { createBy:bddata?.uniqueId }
      );
      // console.log(agencyAdminResponse);

      
      // console.log(bddata);
      if (
        // agencyAdminResponse &&
        // agencyAdminResponse.data &&
        agencyAdminResponse.data.data
      ) {
        setAgencyAdminData(agencyAdminResponse.data.data);
        // toast.success("Agency Admin list successfully retrieved");
      } else {
        toast.error("Failed to retrieve Agency Admin list");
      }
    } catch (error) {
      console.error("Error fetching Agency Admin list:", error);
      // toast.error("Failed to get Agency Admin list");
    }
  };
  useEffect(() => {
   
    manageagencadminApi()
   
  },[bddata] )
  // }, 2000);
  
  
  
  return (
    <>
      <section className="content-header">
        <h1>View BD</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/viewBd">Manage Bd</Link>
          </li>
          <li className="active">View Bd</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${baseUrl}${bddata.image}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </div>
                  <div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Bd
                      </span>
                    </div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {bddata?.name}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bd Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bd Code*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.uniqueId}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.number}
                        readOnly
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>Join Date*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.joinDate}
                        readOnly
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>Hosts*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.hosts}
                        readOnly
                      />
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Password*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={bddata?.password}
                        // readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Aegncy Admin Code</th>
                      <th>Aegncy Admin Name</th>
                      <th>BD Id</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agencyadimindata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img src={`${item.image}`} alt="" width={"80px"} />
                        </td>
                        <td>{item.uniqueId}</td>
                        <td>{item.name}</td>
                        <td>{item.createBy}</td>
                        <td>{item.number}</td>
                        <td>
                          <div className="dropdown">
                            <button
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Action
                              <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right">
                              <li>
                                <Link
                                  to={`/admin/viewagencyadmin/?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/admin/editagencyadmin?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  // onClick={() => deleteagencyadimin(item._id)}
                                  // role="menuitem"
                                  // tabIndex={-1}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewBdData;
