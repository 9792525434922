import React , { useState,useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';

const ViewVip = () => {

    const { viewVipSCat, viewVipsCatS } = useContext(global)
    const [ de , setDe ] = useState(0);
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get('id');

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        viewVipSCat(userId);
    }, [de]);

    return (
        <>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <h1>Manage Vip Package </h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li className="active">Manage Vip Package </li>
                </ol>
            </section>
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        {/* /.box */}
                        <div className="box">
                            {/* /.box-header */}
                            <div className="box-body">
                                <div className="box-header">
                                    <h3 className="box-title"><Link to={`/admin/addVipPack?id=${userId}`} style={{ fontSize: '14px' }} className="btn btn-block btn-success btn-xs">Add Item</Link></h3>
                                    <h3 className="box-title" style={{float:'right'}}><Link to={`/admin/manageVip`} style={{ fontSize: '14px' }} className="btn btn-block btn-success btn-xs">Go Back</Link></h3>
                                </div>
                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Image</th>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="ts">
                                        {

                                            Array.isArray(viewVipsCatS) && viewVipsCatS.map((item, index)=>{
                                                let i = index + 1; // Start index from 1
                                                return(
                                                    <tr>
                                                        <td>{i}</td>
                                                        <td><img src={item.image} width={"80px"} alt="" /></td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <ul className="admin-action btn btn-default" style={{ backgroundColor: '#f4f4f4', color: '#fff !important' }}>
                                                                <li className="dropdown">
                                                                    <a className="dropdown-toggle" style={{ color: 'black' }} data-toggle="dropdown" href="#" aria-expanded="false">
                                                                        Action <span className="caret" />
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                        <li role="presentation">
                                                                            <Link role="menuitem" tabIndex={-1} onClick={()=>{
                                                                                const confirmDelete = window.confirm("Are you sure you want to delete this item?");
                                                                                if (confirmDelete) {
                                                                                    $.ajax({
                                                                                        url: `${process.env.REACT_APP_BASEURL}/admin/vip/deleteVipPack/${item._id}`,
                                                                                        type: "delete",
                                                                                        dataType: "json",
                                                                                        success: function(res) {
                                                                                        if(res.success===1){
                                                                                            toast.success(res.message)
                                                                                            setDe(de+1);
                                                                                        }else{
                                                                                            toast.error(res.message)
                                                                                        }
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }}>Delete</Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )

                                        })}

                                    
                                    </tbody>
                                </table>
                                {/*?php echo $links;?*/}
                            </div>
                            {/* /.box-body */}
                        </div>
                        {/* /.box */}
                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}
            </section>
            {/* /.content */}
        </>
    )
}

export default ViewVip