
import React, {  useEffect, useState } from "react";
import "../Css/Login.css";
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import mastiLive_logo from "../../assets/img/mastiLive_logo.png";
// import {toast} from 'react-toastify'
import { Link } from 'react-router-dom';
const Otpsent = () => {


  const [credentials, setCredentials] = useState({
    number: "",
  });
  const navigate = useNavigate();

// api calling 
const otpsend = async () => {
  try {
    const response = await axios.put(`https://mastishortss.com/video/agency/otpsent?number=${credentials.number}`);
    console.log(response.data.success);
    if (response.data.success === 1) {
      navigate("/agency/forgetpass");
    } else {
      navigate("/agency");
    }
  } catch (error) {
    console.error("Error sending OTP", error);
  }
};
  // useEffect(() => {
  //   if (sessionStorage.getItem("ag"))
  //     window.location.href = "/agency/forgetpass";
  // }, []);
  const handleInputChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  

  return (
    <>
     <div className="login-box">
        <div className="login-logo">
          <a href>
            <img style={{ width: "180px" }} src={mastiLive_logo} />
          </a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Enter your Number </p>
          <div className="form-error"></div>
          <form
            action=""
            method=""
            onSubmit={async(e) => {
              e.preventDefault();
              await otpsend();
         
            }}
          >
            <div className="form-group has-feedback">
              <input
                type="number"
                name="number"
                className="form-control"
                placeholder="Enter your Phone Number"
                value={credentials.number}
                onChange={handleInputChange}
              />
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            
            <div className="row">
              
              <div className="col-xs-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                  // onSubmit={}
                >
                 Sent Otp
                </button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Otpsent;
