import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const GetEvents = () => {
  // State variables
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // Default limit is 5
  const [totalPages, setTotalPages] = useState(1);

  // Function to fetch events from API
  const fetchEvents = async (page, limit) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL5}/admin/banner/getEvents`,
        {
          params: {
            page,
            limit,
          },
        }
      );
      const { details } = response.data;
      setEvents(details);
      // Assuming the response contains the total number of events to calculate total pages
      setTotalPages(Math.ceil(response.data.totalEvents / limit));
    } catch (error) {
      console.error("Error fetching events:", error);
      toast.error("Failed to fetch events");
    }
  };

  // Effect to load events on component mount and when page/limit changes
  useEffect(() => {
    fetchEvents(page, limit);
  }, [page, limit]);

  // Handlers for pagination controls
  const handleNext = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePrev = () => {
    if (page > 1) setPage(page - 1);
  };

  // Handler for limit change
  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setPage(1); // Reset to the first page when limit changes
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this event?");

    if (confirmDelete) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL5}/admin/banner/deleteEvents?id=${id}`
        );

        if (response.data.success === 1) {
          toast.success("Event successfully deleted");
          // Optionally, trigger a refresh or update state to remove the deleted event from the UI
        } else {
          toast.error("Failed to delete the event");
        }
      } catch (error) {
        console.error("Error deleting event:", error);
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <>
      <section className="content-header">
        <h1>Manage Events</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/">
              <i className="fa fa-dashboard"></i> Home
            </a>
          </li>
          <li>
            <a href="#">Manage Events</a>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-warning">
              <div className="box-header with-border">
                <h3 className="box-title">Events List</h3>
              </div>
              <div className="box-body text-dark" style={{ color: "black" }}>
                <div className="form-group">
                  <label htmlFor="itemsPerPage">Items per page:</label>
                  <select
                    id="itemsPerPage"
                    className="form-control"
                    style={{
                      width: "auto",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Form Img</th>
                      <th>LeaderBoard Img</th>
                      <th>Event Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.length === 0 ? (
                      <tr>
                        <td colSpan="5">No events available</td>
                      </tr>
                    ) : (
                      events.map((event, i) => (
                        <tr key={event._id}>
                          <td>{(page - 1) * limit + i + 1}</td>
                          <td><img src={`${event.image}`} width="50px" height="40px" alt="user" /></td>
                          <td><img src={`${event.formImage}`} width="50px" height="40px" alt="user" /></td>
                          <td><img src={`${event.leaderboardImage}`} width="50px" height="40px" alt="user" /></td>
                          <td>{event.name}</td>
                          <td>{event.startDate}, {event.startTime}</td>
                          <td>{event.endDate}, {event.endTime}</td>
                          <td>{event.status}</td>
                          <td>
                            <ul className="admin-action btn btn-default">
                              <li className="dropdown">
                                <a className="dropdown-toggle" style={{ color: "black" }} data-toggle="dropdown" href="#" aria-expanded="false">
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li role="presentation">
                                    <Link role="menuitem" tabIndex={-1} to={`/admin/ViewEvents?id=${event._id}`}>
                                      View
                                    </Link>
                                  </li>
                                  <li role="presentation">
                                    <Link role="menuitem" tabIndex={-1} to={`/admin/EditEvent?id=${event._id}`}>
                                      Edit
                                    </Link>
                                  </li>
                                  <li role="presentation">
                                    <button
                                      role="menuitem"
                                      tabIndex={-1}
                                      onClick={()=>handleDelete(event._id)}
                                      
                                      
                                    >
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                <div className="pagination">
                  <button
                    className="btn btn-default"
                    disabled={page === 1}
                    onClick={handlePrev}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-default"
                    disabled={page === totalPages}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetEvents;
