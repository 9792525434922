import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { value } from '../../config/data'
// import '../Css/Header.css'
 
const AgencyDashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [withdrawalData, setWithdrawalData] = useState(null);
  const [widthdrawalList, setWithdrawalLists] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const location = useLocation();
  useEffect(() => {
    if (!sessionStorage.getItem("agency")) {
      window.location.href = "/agency";
    }
  }, []);
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);
  let id = newAgency._id;
  let code = newAgency.uniqueId;
  const { fetchHosts, fetchHost } = useContext(global);
  const getBdLists = async () => {
    try {
      setLoading(true)
      const today = new Date();
      const currentYear = today.getFullYear();
      const firstDayObj = new Date(currentYear, selectedMonth - 1, 1);
      const firstYear = firstDayObj.getFullYear();
      const firstMonth = String(firstDayObj.getMonth() + 1).padStart(2, '0');
      const firstDay = String(firstDayObj.getDate()).padStart(2, '0');
      const firstFormattedDate = `${firstYear}-${firstMonth}-${firstDay}`;

      let lastDayObj;
      if (selectedMonth === today.getMonth() + 1) {
        lastDayObj = today;
      } else {
        lastDayObj = new Date(currentYear, selectedMonth, 0);
      }
      const lastYear = lastDayObj.getFullYear();
      const lastMonth = String(lastDayObj.getMonth() + 1).padStart(2, '0');
      const lastDay = String(lastDayObj.getDate()).padStart(2, '0');
      const lastFormattedDate = `${lastYear}-${lastMonth}-${lastDay}`;

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL2}/admin/withdrawal/data?start=${firstFormattedDate}&end=${lastFormattedDate}&agency=${id}`
      );

      if (data.success == '1') {
        const totolSumOfWithdrawal = data.details.reduce((acc, total) => {
          const convertedAmount = (((+(total?.coins/value.USD).toFixed(2) + +(total?.bonus?.targetCommission))*value.USDINR));
          return acc + convertedAmount;
        }, 0);
        const totolSumOfCommission = data.details.reduce((acc, total) => {
          
          const convertedAmount = (((+(total?.coins/value.USD).toFixed(2) + +(total?.bonus?.targetCommission))*value.USDINR)) * value.agencyComm;
          return acc + convertedAmount;
        }, 0);
        setWithdrawalLists((totolSumOfWithdrawal+totolSumOfCommission).toFixed(2));
        setLoading(false)
      }
      else
      setLoading(false)

    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchWithdrawalData = async (month) => {
    try {
      setLoading2(true)

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/getForCreatedFor?uniqueId=${code}&month=${month}`
      );
      if (response.data.success == "1") {
        setWithdrawalData(response.data.details);
        setLoading(false)
      } else {
        setWithdrawalData(null);
        setLoading2(false)
      }
    } catch (error) {
      console.error("Error fetching withdrawal data:", error);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem("agency")) {
      window.location.href = "/agency";
    }
    getBdLists();
    fetchWithdrawalData(selectedMonth); // Fetch data for the current month on mount
  }, [selectedMonth]);
  // const agency = sessionStorage.getItem("agency");
  // const newAgency = JSON.parse(agency);
  // let id = newAgency.uniqueId;
  // console.log(id);

  useEffect(() => {
    const agency = sessionStorage.getItem("agency");
    if (agency) {
      const newAgency = JSON.parse(agency);
      let id = newAgency.uniqueId;
      fetchHosts(id);
    } else {
      window.location.href = "/agency";
    }
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      {/* Main content */}
     {loading || loading2?"Loading...": <section className="content">
        {/* Info boxes */}
        <div className="row">
          {/* First Info Box */}
          {!location.pathname === "/agency/dashboard" && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div
                className="info-box green-bg"
                style={{ background: "#06690d", color: "#fff" }}
              >
                <span className="info-box-icon">
                  <i className="fa fa-user" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">
                    <b>Total User</b>
                  </span>
                  <span className="info-box-number">100</span>
                </div>
              </div>
            </div>
          )}
          {/* Second Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Approved Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.accepted}</span>
              </div>
            </div>
          </div>
          {/* Third Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Pending Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.pending}</span>
              </div>
            </div>
          </div>
          {/* Fourth Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Rejected Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.rejected}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h3>Target Withdrawal</h3>
            <div className="form-group">
              <label htmlFor="monthSelect">Select Month:</label>
              <select
                id="monthSelect"
                className="form-control"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString("default", {
                      month: "long",
                    })}
                  </option>
                ))}
              </select>
            </div>
            {withdrawalData ? (
              <div className="info-box" style={{ color: "#000000", padding: "20px" }}>
                <p><strong>Month:</strong> {new Date().toLocaleString('default', { month: 'long' })}</p>
                <p>
                  <strong>Target Amount:</strong> {withdrawalData.amount}
                </p>
                <p>
                  <strong>Your Withdrawal:</strong> {widthdrawalList}
                </p>
                <p>
                  <strong>Created By:</strong> {withdrawalData.createdBy || "Not created"}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(withdrawalData.createdAt).toLocaleString()}
                </p>
                <p>
                  <strong>Last Edited:</strong> {new Date(withdrawalData.updatedAt).toLocaleString()}
                </p>
              </div>
            ) : (
              <div className="info-box" style={{ color: "#000000", padding: "20px" }}>
                <p><strong>Month:</strong> {new Date().toLocaleString('default', { month: 'long' })}</p>
                <p><strong>Target Amount:</strong> 0</p>
                <p><strong>Your Withdrawal:</strong> {widthdrawalList}</p>
                <p><strong>Created By:</strong> Not created</p>
              </div>
            )}
          </div>
        </div>
      </section>}
    </>
  );
};

export default AgencyDashboard;
