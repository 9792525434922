
import React, { useContext, useEffect, useState } from "react";
import "../Css/Login.css";
import { global } from "../../Context/Context";
// import mastiLive_logo from '../../assets/img/mastiLive_logo.png'
import mastiLive_logo from "../../assets/img/mastiLive_logo.png";
// import {toast} from 'react-toastify'
import { Link } from 'react-router-dom';
const  AgAdminOtpsent = () => {
    const { AgencyLogin } = useContext(global);

  const [credentials, setCredentials] = useState({
    number: "",
    password: "",
  });

  useEffect(() => {
    if (sessionStorage.getItem("agency"))
      window.location.href = "/agency/dashboard";
  }, []);
  const handleInputChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
     <div className="login-box">
        <div className="login-logo">
          <a href>
            <img style={{ width: "180px" }} src={mastiLive_logo} />
          </a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Enter your Number </p>
          <div className="form-error"></div>
          <form
            action=""
            method=""
            onSubmit={(e) => {
              e.preventDefault();
              // console.log(credentials)
              AgencyLogin(credentials);
              // toast.success('testing')
            }}
          >
            <div className="form-group has-feedback">
              <input
                type="number"
                name="uniqueId"
                className="form-control"
                placeholder="Enter your Phone Number"
                // value={credentials.phonenumber}
                onChange={handleInputChange}
              />
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            
            <div className="row">
              
              <div className="col-xs-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                >
                  <Link style={{color: 'white'}} to="/agencyadmin/forgetpass">Sent OTP</Link>
                  
                </button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default  AgAdminOtpsent;
