import React, { useContext, useEffect, useState } from "react";
import { Link,useSearchParams } from "react-router-dom";
import axios from "axios";


const FamilyWithdrawalAgbd = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  // const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [loading,setLoading] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);


  // console.log(widthdrawalList)
  const totolSumOfWithdrawal = widthdrawalList.reduce((acc, total) => {
    const convertedAmount = (total.totalAmount);
    return acc + convertedAmount;
  }, 0); 

  const currentDate = new Date();
// console.log(widdthdrawalList)
  // Calculate the date two days before the current date
  const startDateObj = new Date(currentDate);
  startDateObj.setDate(currentDate.getDate() - 7);
  
  // Calculate the date one day before the current date
  const endDateObj = new Date(currentDate);
  endDateObj.setDate(currentDate.getDate());
  
  // Format the start date to YYYY-MM-DD
  const startYear = startDateObj.getFullYear();
  const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0');
  const startDay = String(startDateObj.getDate()).padStart(2, '0');
  const startFormattedDate = `${startYear}-${startMonth}-${startDay}`;
  
  // Format the end date to YYYY-MM-DD
  const endYear = endDateObj.getFullYear();
  const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
  const endDay = String(endDateObj.getDate()).padStart(2, '0');
  const endFormattedDate = `${endYear}-${endMonth}-${endDay}`;
  

  // if (startDate === null) {
  //   setStartDate(startFormattedDate);
  // }
  if (endDate === null) {
    setEndDate(endFormattedDate);
  }

  // console.log(startDate)
  let currentDate1 = new Date();
  let currentDay1 = currentDate1.getDay();
  let prevMonday = new Date(currentDate1);
  prevMonday.setDate(currentDate.getDate() - ((currentDay1 + 6) % 7));
  
let lastMonday = startDate !== null ? startDate : prevMonday.toISOString().split("T")[0];
let [searchParams] = useSearchParams();

const aa = searchParams.get('aa');
const start = searchParams.get('start');
const end = searchParams.get('end');
// console.log(startDate)
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/withdrawal/familyWithAg?start=${start}&end=${end}&aa=${aa}`)
   
    // console.log(data)
    if(data.success == 1) {
      setLoading(false)
      setWithdrawalLists(data.details)

    }
 } catch (error) {
    console.log(error.message)
 }
  }

  // console.log(process.env.REACT_USD);


  useEffect(() => {
    // users();
    getBdLists()
  }, [lastMonday,endDate,startDate]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
 
  // Change page


 
  
  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        {/* {loading && <p>Loading</p>} */}
        <h1>Agency Family Withdrawal</h1>
        
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>

                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                       
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                       
                      </div>
                    </div>
                  </div>
                </div>
              </form>
           
              <div className="box-body">
                <div style={{float:"right",fontWeight:"bolder",marginRight:"2rem" }}>
                  <span>Total : {(totolSumOfWithdrawal).toFixed(2)} Rs /-</span>
                </div>
                
              <div style={{border:"1px solid white",display:"flex",gap:"2rem"}}>
                  <div className="" >
                    <span style={{fontWeight:"bold"}}>From :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{start}</span>
                  </div>
                  <div className="d-flex gap-2">
                    <span style={{fontWeight:"bold"}}>To :</span>
                    <span style={{marginLeft:"2px",fontWeight:"bold"}}>{end}</span>
                  </div>
                </div>
                {/* <!-- Content here --> */}
                <div className="col-md-1">
                  {/* <button className="btn btn-info" style={{ outline: "none" }} onClick={toggleSelectAll}>
                    {selectAll ? "Deselect All" : "Select All"}
                  </button> */}
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    {/* <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th> */}
                      <th>Sr.</th>
                      <th>Agency Id</th>
                      <th>Agency Name</th>
                      <th>Created By</th>
                      <th>INR</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" >Loading...</h6> </td> :   Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr key={index}>
                            {/* <td>
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(item.bd._id)}
                                onChange={() => handleCheckboxChange(item.bd._id)}
                              />
                            </td> */}
                            
                            <td>{i}</td>
                         
                            <td>{item?.agency}</td>
                            <td>{item?.agencyName}</td>
                            <td>{item?.aa}</td>
                            <td>{item?.totalAmount}</td>

                        
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                {/* ... (your existing dropdown menu items) */}
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/bd/storedwithdrawalffamilylists?agency=${item?.agency}&start=${start}&end=${end}`}
                                      >
                                        View
                                      </Link>
                                    </li>
                                  
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                      {widthdrawalList.length == 0 && <h4 style={{textAlign:"center"}} colSpan={4}>No Data found</h4>}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
            
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
// Define active style object
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default FamilyWithdrawalAgbd;
