import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../Css/Header.css";
import { global } from "../../Context/Context";
import axios from "axios";

const CoinPanelDashboard = () => {
  const {
    rechargeHistory,
    received,
  } = useContext(global);

  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);
  // console.log(coinDetails);
  const location = useLocation();
  const [coinDataDetails, setCoinDataDetails] = useState({});
  const [coinDataDetailsBalance, setCoinDataDetailsBalance] = useState();

  // const totalCoinsSend = rechargeHistory.reduce((total, curr) => {
  //   return total + +curr.coin;
  // }, 0);
  // const totalReceivedCoins = received.reduce((total, curr) => {
  //   return total + +curr.coin;
  // }, 0);
  useEffect(() => {
    if (!sessionStorage.getItem("coin")) {
      window.location.href = "/coin";
    }
  }, []);

  const getDataOfCoinPanel = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/coin/recharge/sendCoinfetchagency?userId=${coinDetails.uniqueId}`
      );
     
      setCoinDataDetails(data.details);
      // console.log(dataBalance);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getDataOfCoinPanel1 = async () => {
    try {
     
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/coin/recharge/recieveSendAmount?uniqueId=${coinDetails.uniqueId}`
      );
      setCoinDataDetailsBalance(data?.details[0]);
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(coinDataDetails);
  useEffect(() => {
    getDataOfCoinPanel();
    getDataOfCoinPanel1()
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        {/* Info boxes */}
        <div className="row">
          {/* First Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Coin Balance</b>
                </span>
                <span className="info-box-number">
                  {coinDataDetails?.coins}
                </span>
              </div>
            </div>
          </div>

          {/* Second Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>TOTAL RECEIVE COINS</b>
                </span>
                <span className="info-box-number">{coinDataDetailsBalance?.receiving}</span>
              </div>
            </div>
          </div>
          {/* Third Info Box */}
          {/* <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Send Coins</b>
                </span>
                <span className="info-box-number">20</span>
              </div>
            </div>
          </div> */}
          {/* Fourth Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Send Coins</b>
                </span>
                <span className="info-box-number">{coinDataDetailsBalance?.sending}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoinPanelDashboard;
