import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const FamilyWalletWithdrawal = ({ status }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [withdrawal, setWithdrawal] = useState([]);

  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;

  const withdrawalListsUpdate = async (id, status) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL3}/admin/withdrawal/withdrawalreq?id=${id}&status=${status}`
      );

      if (data.success == 1) {
        toast.success(data.message);
        fetchWithdrawalLists();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchWithdrawalLists = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/withdrawal/getTransactionFamilyWith?status=${status}&page=${currentPage}&limit=${entriesPerPage}`
      );

      if (data.success === 1) {
        setWithdrawal(data.details);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchWithdrawalLists();
  }, [status, currentPage, entriesPerPage]);

  useEffect(() => {
    searchUser(search);
  }, [search]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(withdrawal.map((item) => item._id));
    }
    setSelectAll(!selectAll);
  };

  const searchUser = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/withdrawal/searchInFamilyWith?search=${search}&status=${status}`
      );
      setWithdrawal(data.details);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  return (
    <>
      <section className="content-header">
        <h1>Family Withdrawal List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Withdrawal List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div
                    className="col-md-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          placeholder="Search User"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 ">
                  <button
                    className="btn btn-info"
                    style={{ outline: "none" }}
                    onClick={toggleSelectAll}
                  >
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>
               

                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th>
                      <th>SR.</th>
                      <th>Date</th>
                      <th>Leader username</th>
                      <th>F. name</th>
                      <th>INR</th>
                      <th>ACC NO</th>
                      <th>BANK NAME</th>
                      <th>IFSC CODE</th>
                      <th>STATUS</th>
                      {status != "2" && <th>ACTION</th>}
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {Array.isArray(withdrawal) &&
                      withdrawal.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        let d = new Date(item?.createdAt);
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(item._id)}
                                onChange={() => handleCheckboxChange(item._id)}
                              />
                            </td>
                            <td>{i}</td>
                            <td>
                              {new Date(d).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              })}
                            </td>
                            <td>{item?.userId?.username}</td>
                            <td>{item?.familyId?.name}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.accountNo}</td>
                            <td>{item?.bankName}</td>
                            <td>{item?.ifscCode}</td>
                            <td>
                              {item.status == "0" ? (
                                <span className="label label-info">Pending</span>
                              ) : item.status == "1" ? (
                                <span className="label label-success">
                                  Approved
                                </span>
                              ) : (
                                <span className="label label-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>
                              {status != "2" && (
                                <ul
                                  className="admin-action btn btn-default"
                                  style={{
                                    backgroundColor: "#f4f4f4",
                                    color: "#fff !important",
                                  }}
                                >
                                  <li className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      style={{ color: "black" }}
                                      data-toggle="dropdown"
                                      href="#"
                                      aria-expanded="false"
                                    >
                                      Action <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                      {item.status == 0 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              let status = 1;
                                              withdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              let status = 2;
                                              setId(item._id);
                                              withdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                        </>
                                      ) : item.status == 1 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              let status = 2;
                                              setId(item._id);
                                              withdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              let status = 1;
                                              setId(item._id);
                                              withdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </li>
                                </ul>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* Pagination links */}
                <div className="pagination">
                  <button
                    className="btn btn-primary"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>
                  <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
                  <button
                    className="btn btn-primary"
                    onClick={handleNextPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyWalletWithdrawal;
