
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { global } from "../../Context/Context";
import axios from "axios";
const  AgencyListShow = () => {
const baseUrl = process.env.REACT_APP_BASEURL3
  const {
    fetchagencyPanel,
    aprrovedHost,
    fetchHost,
    fetchagencyPanell,
    // getHosts,
    // totalHosts,
    // totalPages,
    // setTotalPages,
    // page,
    // setPage,
    get
  } = useContext(global);

  const [abc, setAbc] = useState(0);
  const [totalHosts, setTotalHosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);

  // const agency = sessionStorage.getItem("agency");
  // // const agency 
  // const newAgency = JSON.parse(agency);
const status = fetchagencyPanell?.status
let id;
if (fetchagencyPanell?.old == "1") {
  id = fetchagencyPanell.sqlId;
  } else {
    id = fetchagencyPanell?.uniqueId;
  }

  const filterData = totalHosts?.filter((d) => d.status !== status);
 
 
/////////
const getHosts = async (id, status) => {
  try {
    // console.log("inside api fn",id)
    setTotalHosts([])
    let data = await axios.get(
      `${baseUrl}/agency/host/gethosts?id=${id}&status=${status}&page=${page}&limit=${limit}`,
     
    );

    
    // console.log(data.data);

    setTotalHosts(data.data.details);
    setTotalPages(data.data.pages);
    // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));

    // setTimeout(() => {
    //   window.location.href = "/coin/dashboard";
    // }, 1000);
  } catch (err) {
    // toast.error(err.message);
    console.log(err.message)
  }
};

 
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get("id");
    $("#specialitiesForm")[0].reset();
    
    fetchagencyPanel(userId);
    }, [page]);
  
  const createdAtDate = fetchagencyPanell?.createdAt
  ? new Date(fetchagencyPanell.createdAt)
  : null;
  const formattedDate = createdAtDate
  ? createdAtDate.toISOString().split("T")[0]
    : "";
  // console.log(fetchagencyPanel);
  /////////////////
  
  ////////////////
  // console.log(getHosts)
  // console.log(fetchagencyPanell);
  useEffect(() => {
    // aprrovedHost();
   
    getHosts(id,status);
  }, [id , status,page,limit]);
  return (
    <>
      <section className="content-header">
        <h1>View Agency</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageAgency">Manage Agency</Link>
          </li>
          <li className="active">View Agency</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${process.env.REACT_APP_BASEURL1}${fetchagencyPanell?.image}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </div>
                  <div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {fetchagencyPanell?.uniqueId}{" "}
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Agency
                      </span>
                    </div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {fetchagencyPanell?.name}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Agency Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={fetchagencyPanell?.name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Agency Code*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={fetchagencyPanell?.uniqueId}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={fetchagencyPanell?.number}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Join Date*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={formattedDate}
                        readOnly
                      />
                    </div>
                  </div>
                
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* ////////////// */}

      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          {/* <li className="active">Host List</li> */}
        </ol>
      </section>
      <section className="content">
        <div className="row">
        <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => {
                        setPage(1)
                        setLimit(Number(e.target.value))
                      }}
                      className="form-control"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Name</th>
                      {/* <th>Phone</th> */}
                      <th>Agency Code</th>
                      <th>Status</th>
                    
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {totalHosts?.map((host, i) => (
                      <tr key={host._id}>
                  <td>{(page - 1) * limit + i + 1}</td>
                        
                        <td>{host?.username}</td>
                        <td>{host?.realName}</td>
                        {/* <td>{host.number}</td> */}
                        <td>{fetchagencyPanell.uniqueId}</td>
                        <td>
                          <span
                            className={`label  ${
                              status == 0
                                ? "label-info"
                                : status == 1
                                ? "label-success"
                                : "label-danger"
                            }`}
                          >
                            {status == 0
                              ? "Pending"
                              : status == 1
                              ? "Aprroved"
                              : "Rejected"}
                          </span>
                        </td>
                         
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div>
                  <ul className="pagination">
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page > 1) {
                            setPage((prev) => prev - 1);
                          }
                        }}
                      >
                        Previous
                      </span>
                    </li>
                    {/* Example pagination links */}

                   

                    {/* Add more pagination links as needed */}
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page < totalPages) {
                            setPage((prev) => prev + 1);
                            getHosts(id,"1")
                          }
                        }}
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////// */}
    </>
  );
};

export default AgencyListShow;
