    import React, { useContext, useEffect, useState } from "react";
    import { Link, useNavigate } from "react-router-dom";
    import $ from "jquery";
    import { toast } from "react-toastify";
    import { global } from "../../Context/Context";
import axios from "axios"
    const EditBdAgency= () => {
    const { fetchagencyPanel, fetchagencyPanell } = useContext(global);
const baseUrl = process.env.REACT_APP_BASEURL2;
const [agadmin, setagadmin] = useState([])
const [adminValue, setAdminValue] = useState("");

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get("id");

        $("#form")[0].reset(); // Reset the form using jQuery
        fetchagencyPanel(userId);
    }, []);
    const viewAgencyadmin = async()=>{
        try {
            const response  = await axios.post(`${baseUrl}/agencadminpanel/getagencyadmin`,{
                createBy: fetchagencyPanell.bd
            })
            setagadmin(response.data.data)
        } catch (error) {
            
        }
    }
    useEffect(() => {
        if (fetchagencyPanell) {
            setAdminValue(fetchagencyPanell.admin);
            viewAgencyadmin();
        }
    }, [fetchagencyPanell]);
    const navigate = useNavigate();
    const subbbb = async(e) => {
        e.preventDefault();
        $.ajax({
        url: `${baseUrl}/admin/agencyPanel/updateAgency?id=${fetchagencyPanell._id}`,
        type: "put",
        dataType: "json",
        data: {admin : adminValue},
        success: function (res) {
            if (res.success == 1) {
            toast.success(res.message);
            setTimeout(() => {
                navigate("/bd/manageagency");
            }, 1000);
            } else {
            toast.error(res.message);
            }
        },
        });
    };
useEffect(() => {
  viewAgencyadmin()

 
}, [fetchagencyPanell])
console.log(fetchagencyPanell._id)
    return (
        <>
        <section className="content-header">
            <h1>Edit Agency</h1>
            <ol className="breadcrumb">
            <li>
                <Link to="/admin">
                <i className="fa fa-dashboard"></i> Home
                </Link>
            </li>
            <li>
                <Link to="/bd/manageAgency">Manage Agency</Link>
            </li>
            <li className="active">Edit Agency</li>
            </ol>
        </section>
        <section className="content">
            <div className="row">
            <div className="col-md-10">
                <form
                role="form"
                method="post"
                id="form"
                onSubmit={subbbb}
                encType="multipart/form-data"
                >
                <div className="box box-warning">
                    <div className="box-header with-border">
                    <h3 className="box-title">Edit</h3>
                    </div>
                    <div className="box-body">
                    <input
                        type="hidden"
                        name="id"
                        defaultValue={fetchagencyPanell._id}
                    />
                    <div className="form-group">
                        <label>Agency Name*</label>
                        <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Agency Name"
                        defaultValue={fetchagencyPanell.name}
                        readOnly
                        />
                        <div className="form-error1" />
                    </div>
                    <div className="form-group">
                        <label>Agency Email*</label>
                        <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Agency Email"
                        defaultValue={fetchagencyPanell.email}
                        readOnly
                        />
                        <div className="form-error1" />
                    </div>
                    <div className="form-group">
                        <label>Agency Mobile*</label>
                        <input
                        type="text"
                        className="form-control"
                        name="number"
                        placeholder="Agency Mobile"
                        defaultValue={fetchagencyPanell.number}
                        readOnly
                        />
                        <div className="form-error1" />
                    </div>
                    <div className="form-group">
                        <label>Agency Password*</label>
                        <input
                        type="text"
                        className="form-control"
                        name="password"
                        placeholder="Agency Password"
                        defaultValue={fetchagencyPanell.password}
                        readOnly
                        />
                        <div className="form-error1" />
                    </div>
                    <div className="form-group">
                        <label>BD</label>
                        <input
                        type="text"
                        className="form-control"
                        name="bd"
                        placeholder="Bd"
                        defaultValue={fetchagencyPanell.bd}
                        readOnly
                        />
                        <div className="form-error1" />
                    </div>
                    <div className="form-group">
                                        <label>Admin</label>
                                        <select
                                            className="form-control"
                                            name="admin"
                                            value={adminValue}
                                            onChange={(e) => setAdminValue(e.target.value)}
                                        >
                                            {agadmin.map((admin, index) => (
                                                <option key={index} value={admin.uniqueId}>
                                                    {admin.uniqueId}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="form-error1" />
                                    </div>
                    <div className="form-group">
                        <label>Agency Image*</label>
                        <input type="file" className="form-control" name="image" 
                            readOnly
                            />
                        <div className="form-error1" />
                        
                    </div>
                    <div className="form-group">
                        <label>Old Icon*</label>
                        <br />
                        <img src={fetchagencyPanell.image} alt="" height={"80px"} />
                    </div>
                    {/* Repeat other form fields as needed */}
                    <div className="form-group">
                        <button type="reset" className="btn btn-danger">
                        Cancel
                        </button>
                        <input
                        type="submit"
                        className="btn btn-success pull-right"
                        name="submit"
                        defaultValue="Submit"
                        />
                    </div>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </section>
        </>
    );
    };

    export default EditBdAgency;
