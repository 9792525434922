import React from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'

const AddSongs = () => {

    const navigate = useNavigate();

    const subbbb = (e) => {
        e.preventDefault();
        var a = $("input[name='songName']").val();
        var b = $("input[name='songs']").val();
        let text = "This field cannot be empty";
        if(a == ''){
            $('.form-error1').html(text); 
        }
        if(b == ''){
            $('.form-error2').html(text);
        }else if(a != '' && b != ''){
            let formData = new FormData(e.target); // Use e.target to access the form element
            $.ajax({
                url: `${process.env.REACT_APP_BASEURL}/admin/songs/`,
                type: "post",
                dataType: "json",
                data: formData,
                contentType: false,
                processData: false,
                success: function(res) {
                if(res.success===1){
                    toast.success(res.message)
                    setTimeout(()=>{ 
                            navigate('/admin/manageSongs')
                    },1000)
                }else{
                    toast.error(res.message)
                }
                }
            });
        }
        
    }

    return (
        <>
            <section className="content-header">
                <h1>Add Songs</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageTag">Manage Songs</Link></li>
                    <li className="active">Add Songs</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data" id="form">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="songName" placeholder="Title" onChange={()=>{
                                            $('.form-error1').html('');
                                        }}/>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Songs*</label>
                                        <input type="file"   accept="audio/*"
                                         className="form-control" name="songs" onChange={()=>{
                                            $('.form-error2').html('');
                                        }} />
                                        <div className="form-error2 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddSongs