import React , { useContext, useEffect } from 'react'
import { Link  } from "react-router-dom";
import { global } from '../../Context/Context';
import $ from 'jquery';

const ViewCoin = () => {

    const { fetchcoinPanel, fetchCoinPanel } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#specialitiesForm')[0].reset();

        fetchcoinPanel(userId);
    }, []);

    const createdAtDate = fetchCoinPanel.createdAt ? new Date(fetchCoinPanel.createdAt) : null;
    const formattedDate = createdAtDate ? createdAtDate.toISOString().split('T')[0] : '';

  return (
    <>
            <section className="content-header">
                <h1>View Coin Agency</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageCoin">Manage Coin Agency</Link></li>
                    <li className="active">View Coin Agency</li>
                </ol>
            </section>
            <section className="content">
                <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="col-md-10 col-12">
                        <form role="form" method="post" id="specialitiesForm" encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border" style={{ display: 'flex' }}>
                                    <div style={{ position: 'relative' }}>
                                        <img src={fetchCoinPanel.image} style={{ width: '80px', height: '80px', borderRadius: '50%' }} id="pro_img" />
                                        <button type="button" id="edit_btn" style={{ position: 'absolute', top: '-5px', right: '-5px', background: 'transparent', border: 'none' }}>
                                            <i className="fa-solid fa-pen-to-square" />
                                        </button>
                                    </div>
                                    <div>
                                        <div style={{ marginLeft: '15px', fontSize: '20px' }}>{fetchCoinPanel.uniqueId} <span className=" label label-warning" style={{ fontSize: '10px' }} aria-label="user type">Coin Agency</span></div>
                                        <div style={{ marginLeft: '15px', fontSize: '20px' }}>{(fetchCoinPanel.name ) }</div>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Coin Agency Name*</label>
                                            <input type="text" className="form-control" defaultValue={fetchCoinPanel.name} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Coin Agency Code*</label>
                                            <input type="text" className="form-control" defaultValue={fetchCoinPanel.uniqueId} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Number*</label>
                                            <input type="text" className="form-control" defaultValue={fetchCoinPanel.number} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Join Date*</label>
                                            <input type="text" className="form-control" defaultValue={formattedDate} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Coins*</label>
                                            <input type="text" className="form-control" defaultValue={fetchCoinPanel.coins} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Password*</label>
                                            <input type="text" className="form-control" defaultValue={fetchCoinPanel.password} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
    </>
  )
}

export default ViewCoin