import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import { toast } from "react-toastify";
import _ from "lodash";

const BdHost = ({ status }) => {
    const baseUrl = process.env.REACT_APP_BASEURL3

  const [list, setList] = useState([])
  const [reload, setReload] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");



  
  

let bddata = JSON.parse(sessionStorage.getItem("bd"))
let bdname = bddata.uniqueId
 
//   ==========================
const agencyadminlist = async ()=>{
  setList([])
  setSearchQuery("")

    const response = await axios.get(`${baseUrl}/agency/host/getpenbdlist?status=${status}&bdname=${bdname}&page=${page}&limit=${limit}`)//${bdname}
    setList(response.data.details)
    setCount(response.data.totalhosts);


}
useEffect(() => {
 
 agencyadminlist()
}, [status , reload,page,limit])

// =============================
const data1 =async (status,id)=>{
  const response = await axios.get(`${baseUrl}/agency/host/hostrequestsentbd?status=${status}&id=${id}`)   

  if(response.data.success == 1)
    {
        toast.success(response.data.message);
    }else {
      toast.error(response.data.message);
    }

    setReload(!reload);
} 

const agencyadminlistsearch = async (query = "") => {
  try {
    setList([])
    const response = await axios.get(`${baseUrl}/agency/host/getpenbdlistsearch`, {
      params: {
        status,
        bdname: JSON.parse(sessionStorage.getItem("bd")).uniqueId,
        search: query,
      },
    });
    setList(response.data.details);
    setCount(response.data.totalhosts);
  } catch (error) {
    console.error(error);
  }
};
const handleSearchChange = useCallback(
  _.debounce((value) => {
    setSearchQuery(value)
    agencyadminlistsearch(value);
  }, 1000),
  []
);
  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
        <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => setLimit(Number(e.target.value))}
                      className="form-control"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Agency Name</th>
                      <th>Agency Code</th>
                      <th>Agency Admin Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {list.length === 0 ? (
                      "Loading..."
                    ) : (list.map((host, i) => (
                      <tr key={host._id}>
                          <td>{(count - limit * (page - 1)) - i}</td>
                          <td>{new Date(host?.updatedAt).toLocaleString()}</td>
                          <td>{host?.username}</td>
                        <td>{host?.realName}</td>
                        <td>{host?.agencyCode}</td>
                        <td>{host?.agencyAdmin}</td>
                        <td>
                          <span
                            className={`label  ${
                              host?.status == 0
                                ? "label-info"
                                : host?.status == 1
                                ? "label-success" 
                                : host?.status == 3
                                ? "label-primary" 
                                : host?.status == 4
                                ? "label-primary"
                              : "label-danger"
                            }`}
                          >
                            {host?.status == 0
                              ? "Pending"
                              : host?.status == 1
                              ? "Approved"
                              : host?.status == 3
                              ? "Approved by agency"
                              : host?.status == 4
                              ? "Approved by agency admin"
                              : "Rejected"}
                          </span>
                        </td>
                        <td>
                          <ul
                            className="admin-action btn btn-default"
                            style={{
                              backgroundColor: "#f4f4f4",
                              color: "#fff !important",
                            }}
                          >
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                style={{ color: "black" }}
                                data-toggle="dropdown"
                                href="#"
                                aria-expanded="false"
                              >
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                {status == 0 ? (
                                  <>
                                    <li
                                      role="presentation"
                                      onClick={() => {
                                        let id = host._id
                                        let status = "1"
                                       data1(status, id)
                                       
                                       
                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Aprroved
                                      </Link>
                                    </li>
                                    <li
                                      role="presentation"
                                      onClick={async() => {
                                        let id = host._id
                                        let status = "2"
                                        const confirmDelete = window.confirm(
                                          "Are you sure you want to delete this item?"
                                        );
                                        if(confirmDelete) 
                                       data1(status, id)

                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Reject
                                      </Link>
                                    </li>
                                  </>
                                )
                                 : status ==1 ? (
                                  <>
                                 
                                  </>

                                ) : (
                                  <>
                                  </>
                                  // <li
                                  //   role="presentation"
                                  //   onClick={async() => {
                                  //     await aprrovedHost({
                                  //       id: host._id,
                                  //       status: 1,
                                  //     });
                                  //     await getHosts(id, status);

                                  //   }}
                                  // >
                                  //   <Link role="menuitem" tabIndex={-1} to="">
                                  //     Aprroved
                                  //   </Link>
                                  // </li>
                                )}
                       
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    )))}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div className="row">
                      <div className="col-md-5 text-bold">
      
                          Total entries : {count} 
                  
                      </div>
                      <div className="pagination col-md-5">
                      <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) => (prev < Math.ceil(count / limit) ? prev + 1 : prev))
                        }
                      >
                        Next
                      </span>
                    </li>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BdHost