import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
const AgencyLists = ({ status }) => {
  const {
    getHosts,
    totalHosts,
    aprrovedHost,
    totalPages,
    setTotalPages,
    page,
    setPage,
  } = useContext(global);
  const [abc, setAbc] = useState(0);
  const [agencyLists, setAgencyLists] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);

  //   let id;
  //   if (newAgency?.old == "1") {
  //     id = newAgency.sqlId;
  //   } else {
  //     id = newAgency?.uniqueId;
  //   }

  //   console.log(agencyLists);
  //   const filterData = totalHosts?.filter((d) => d.status !== status);

  const fetchListsOfAgency = async () => {
    try {
      const agencyData = JSON.parse(sessionStorage.getItem("agencyadmin"));
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL1}/agencyadmin/getAll`,
        { admin: agencyData.uniqueId }
      );
      // console.log(data);
      setAgencyLists(data.details);
      setDataFetched(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchListsOfAgency();
  }, [dataFetched]);

  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      {/* <th>Username</th> */}
                      <th>Name</th>
                      {/* <th>Unique Id</th> */}
                      <th>Agency Code</th>
                      {/* <th>Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {agencyLists?.map((host, i) => (
                      <tr key={host._id}>
                        <td>{i + 1}</td>
                        <td>{host?.name}</td>
                        {/* <td>{host?.realName}</td> */}
                        {/* <td>{host.uniqueId}</td> */}
                        <td>{host.uniqueId}</td>
                        {/* <td>
                          <span
                            className={`label  ${
                              status === 0
                                ? "label-info"
                                : status === 1
                                ? "label-success"
                                : "label-danger"
                            }`}
                          >
                            {status === 0
                              ? "Pending"
                              : status === 1
                              ? "Aprroved"
                              : "Rejected"}
                          </span>
                        </td> */}
                        <td>
                          <ul
                            className="admin-action btn btn-default"
                            style={{
                              backgroundColor: "#f4f4f4",
                              color: "#fff !important",
                            }}
                          >
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                style={{ color: "black" }}
                                data-toggle="dropdown"
                                href="#"
                                aria-expanded="false"
                              >
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                <li
                                  role="presentation"
                                  onClick={() => {
                                    // aprrovedHost({
                                    //   id: host._id,
                                    //   status: 1,
                                    // });
                                  }}
                                >
                                  <Link
                                    to={`/agencyadmin/hosts/${host.uniqueId}`}
                                    role="menuitem"
                                    tabIndex={-1}
                                  >
                                    View
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div>
                  <ul className="pagination">
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page > 1) {
                            setPage((prev) => prev - 1);
                          }
                        }}
                      >
                        Previous
                      </span>
                    </li>
                    {/* Example pagination links */}

                    {Array.from({ length: totalPages }).map((el, i) => (
                      <li className={`${page === i + 1 ? "active" : ""}`}>
                        <a
                          href=""
                          onClick={() => {
                            setPage(el);
                          }}
                        >
                          {i + 1}
                        </a>
                      </li>
                    ))}

                    {/* Add more pagination links as needed */}
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page < totalPages) {
                            setPage((prev) => prev + 1);
                          }
                        }}
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencyLists;
