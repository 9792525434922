import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import {  toast } from 'react-toastify';
import _ from "lodash";
import { useSearchParams } from 'react-router-dom';
import Modal from 'react-modal';
Modal.setAppElement('#root'); // Set the root element for accessibility

const ViewTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [widthdrawal, setWidthdrawal] = useState([]);
  const widthdrawalLists = async () => {
    try {
      setIsLoading(true)
      setWidthdrawal([])
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/withdrawal/getFamilyTransactions?familyId=${id}&page=${page}&limit=${limit}`
      );

      // let res = await data.json();

      if (data.success == 1) {
        // toast.success(data.message);
        setWidthdrawal(data.details);
        setCount(data.total)

     
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false)
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    widthdrawalLists();
  }, [page,limit]);


  return (
    <>
      <section className="content-header">
      <style jsx>{`
        .rotating-logo {
          width: 100px;
          height: 100px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
      <Modal
        isOpen={isLoading}
        contentLabel="Loading Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center', // Center the content
          },
        }}
      >
        <img src='/mastiLive_logo.png' alt="Loading..." className="rotating-logo" />
        <h1>Loading...</h1>
      </Modal>
        <h1>Widthdrawal List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Widthdrawal List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => {
                        setPage(1)
                        setLimit(Number(e.target.value))
                      }}
                      className="form-control"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  
                  
                </div>
              
                
              
                <table id="example1" className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>SR.</th>
                      <th>Username</th>
                      <th>Date</th>
                      <th>Com.</th>
                      <th>Av. Balance</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {widthdrawal.length === 0 ? (
                      "Loading..."
                    ):
                    Array.isArray(widthdrawal) && widthdrawal.map((item, index) => {
                      let i =   (page - 1) * limit + (index + 1);;
                      let d = new Date(item?.createdAt);
                      return (
                        <tr key={item._id}>
                        
                          <td>{i}</td>
                          <td>{item?.userId?.username}</td>
                          <td>{new Date(d).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</td>
                          <td>{item.status=="1"?"+ ":item.status=="4"?"- ":""}{item?.familyCommission}</td>
                          <td>{item?.availableBalance}</td>
                         
                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="row">
                      <div className="col-md-5 text-bold">
      
                          Total entries : {count} 
                  
                      </div>
                      <div className="pagination col-md-5">
                      <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) => (prev < Math.ceil(count / limit) ? prev + 1 : prev))
                        }
                      >
                        Next
                      </span>
                    </li>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewTransactions;
