import React, { useState,useContext, useEffect } from 'react'
import { Link } from "react-router-dom";
import { global } from '../../Context/Context';
import $ from 'jquery';
import {toast} from 'react-toastify'

const LiveGiftCategory = () => {
    const {viewLiveGiftCategorys , viewLiveGiftCategory} = useContext(global)
    const [ de , setDe ] = useState(0);
    useEffect(()=>{
        viewLiveGiftCategorys()
    },[de])
  return (
    <>
         {/* Content Header (Page header) */}
         <section className="content-header">
                <h1>
                    Manage LiveGift Category
                </h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard" /> Home</Link></li>
                    <li className="active">Manage LiveGift Category</li>
                </ol>
            </section>
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
                            <div className="box-header">
                                <h3 className="box-title"><Link to="/admin/addLiveGiftCategory" style={{ fontSize: '14px' }} className="btn btn-block btn-success btn-xs">Add Live Gift Category</Link></h3>
                            </div>
                            <div className="box-body">
                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="ts">
                                        {/* Hard-coded data for demonstration */}

                                        {
                                            Array.isArray(viewLiveGiftCategory) && viewLiveGiftCategory.map((item,index)=>{
                                                let i = index + 1;
                                                return(
                                                    <tr>
                                                        <td>{i}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <ul className="admin-action btn btn-default" style={{ backgroundColor: '#f4f4f4', color: '#fff !important' }}>
                                                                <li className="dropdown">
                                                                    <a className="dropdown-toggle" style={{ color: 'black' }} data-toggle="dropdown" href="#" aria-expanded="false">
                                                                        Action <span className="caret" />
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                        <li role="presentation">
                                                                            <Link role="menuitem" tabIndex={-1} to={`/admin/editLiveGiftCategory?id=${item._id}`} >Edit</Link>
                                                                        </li>
                                                                        <li role="presentation">
                                                                            <Link role="menuitem" tabIndex={-1} onClick={()=>{
                                                                                const confirmDelete = window.confirm("Are you sure you want to delete this item?");
                                                                                if (confirmDelete) {
                                                                                    $.ajax({
                                                                                        url: `${process.env.REACT_APP_BASEURL}/admin/livegiftcategory/deleteLiveGiftscategory/${item._id}`,
                                                                                        type: "delete",
                                                                                        dataType: "json",
                                                                                        success: function(res) {
                                                                                        if(res.success===1){
                                                                                            toast.success(res.message)
                                                                                            setDe(de+1);
                                                                                        }else{
                                                                                            toast.error(res.message)
                                                                                        }
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }}>Delete</Link>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )}
                                            )
                                                
                                        }

                                    </tbody>
                                </table>
                                {/* Pagination section can be added here (if applicable) */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default LiveGiftCategory