import React from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'

const AddFrames = () => {

    const navigate = useNavigate();

    const subbbb = (e) => {
        e.preventDefault();
        var a = $("select[name='type']").val();
        var b = $("input[name='title']").val();
        var c = $("input[name='price']").val();
        var d = $("input[name='valid']").val();
        var e = $("input[name='svga']")[0].files[0]; // Access the file object
        var f = $("input[name='thumbnail']")[0].files[0]; // Access the file object
        let text = "This field cannot be empty";
        if(a == ''){
            $('.form-error1').html(text);
        }
        if(b == ''){
            $('.form-error2').html(text);
        }
        if(c == ''){
            $('.form-error3').html(text);
        }
        if(d == ''){
            $('.form-error4').html(text);
        }
        if(e == ''){
            $('.form-error5').html(text);
        }
        if(f == ''){
            $('.form-error6').html(text);
        }
        else if (a != '' && b != '' && c != '' && d != '' && e != '' && f != '')
        {
            // let formData = new FormData(e.target); // Use e.target to access the form element
            let formData = new FormData();
            formData.append('type', a);
            formData.append('title', b);
            formData.append('price', c);
            formData.append('valid', d);
            formData.append('svga', e);
            formData.append('thumbnail', f);

            $.ajax({
                url: `${process.env.REACT_APP_BASEURL}/admin/frame/addframes`,
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                success: function(res) {
                  if(res.success===1){
                      toast.success(res.message)
                      setTimeout(()=>{
                            navigate('/admin/manageFrames')
                      },1000)
                  }else{
                      toast.error(res.message)
                  }
                }
            });
        }
    }




  return (
    <>
    <section className="content-header">
                <h1>Add Frame</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageFrames">Manage Frame</Link></li>
                    <li className="active">Add Frame</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Type*</label>
                                        <select type="text" className="form-control" name="type" onChange={()=>{
                                            $('.form-error1').html('');
                                        }}>
                                            <option value="">Select Type</option>
                                            <option value="ADMIN">ADMIN</option>
                                            <option value="PURCHASED">PURCHASED</option>
                                        </select>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" onChange={()=>{
                                            $('.form-error2').html('');
                                        }} />
                                        <div className="form-error2 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Price*</label>
                                        <input type="number" className="form-control" name="price" placeholder="price" onChange={()=>{
                                            $('.form-error3').html('');
                                        }} />
                                        <div className="form-error3 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Valid Days*</label>
                                        <input type="number" className="form-control" name="valid" placeholder="valid days" onChange={()=>{
                                            $('.form-error4').html('');
                                        }} />
                                        <div className="form-error4 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Svga*</label>
                                        <input type="file" className="form-control" name="svga" onChange={()=>{
                                            $('.form-error5').html('');
                                        }} />
                                        <div className="form-error5 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Thumbnail*</label>
                                        <input type="file" className="form-control" name="thumbnail" onChange={()=>{
                                            $('.form-error6').html('');
                                        }} />
                                        <div className="form-error6 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" placeholder="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
    </>
  )
}

export default AddFrames