import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = `${process.env.REACT_APP_BASEURL5}/admin/banner`;

let checkImageUrl = async (url) => {
  try {
    const response = await fetch(url, { method: "HEAD" });
    return response.ok ? true : false;
  } catch (error) {
    console.log(error);
  }
};

const unregisterEvent = async (id) => {
  try {
    const userConfirmed = window.confirm(
      "Are you sure you want to settle GiftsEvent?"
    );
    if (userConfirmed) {
      const response = await axios.post(`${baseUrl}/unregister`, {
        id: id,
      });
      if (response.data.success == 1) toast.success("unregister successfully");
      else toast.error(response.data.message);
    }
  } catch (error) {
    console.error("Error sending notification:", error);
    alert("Failed to settle GiftsEvent.");
  }
};
const ViewEvent = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const eventId = params.get("id");
  const [eventDetails, setEventDetails] = useState({});
  const [activeTab, setActiveTab] = useState("registered"); // registered or ranking
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [rankingUsers, setRankingUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  // Fetch event details
  const fetchEventDetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/getEventById?eventId=${eventId}`
      );
      setEventDetails(data.details);
    } catch (err) {
      console.error("Error fetching event details:", err);
    }
  };

  // Fetch registered users or ranking users based on activeTab
  const fetchUsers = async (type) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/viewEvent?eventId=${eventId}&type=${type}&page=${page}&limit=${limit}`
      );
      if (type === 0) {
        setRegisteredUsers(data.details);
      } else {
        setRankingUsers(data.details);
      }
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };
  const sendNotification = async () => {
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to send the notification to all registered users?"
      );
      if (userConfirmed) {
        const response = await axios.post(`${baseUrl}/sendNotificationToReg`, {
          id: eventId,
        });
        if (response.data.success == 1)
          toast.success("Notification sent successfully!");
        else toast.error("Failed to send notification");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Failed to send notification.");
    }
  };
  const settleGiftsEvent = async () => {
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to settle GiftsEvent?"
      );
      if (userConfirmed) {
        const response = await axios.post(`${baseUrl}/settleGiftsEvent`, {
          eventId: eventId,
        });
        if (response.data.success == 1)
          toast.success("settle GiftsEvent successfully!");
        else toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Failed to settle GiftsEvent.");
    }
  };

  useEffect(() => {
    fetchEventDetails();
    fetchUsers(activeTab === "registered" ? 0 : 1);
  }, [activeTab, page, limit]);

  return (
    <>
      <section className="content-header">
        <h1>View Event</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/GetEvents">Manage Events</Link>
          </li>
          <li className="active">View Event</li>
        </ol>
      </section>

      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <div className="box box-warning">
              {/* Event Images Section */}
              <div
                className="box-header with-border"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <h4 style={{ textAlign: "center" }}>Main Image</h4>
                    <img
                      src={eventDetails.image}
                      alt="Event"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4 style={{ textAlign: "center" }}>Form Image</h4>
                    <img
                      src={eventDetails.formImage}
                      alt="Form"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4 style={{ textAlign: "center" }}>Leaderboard Image</h4>
                    <img
                      src={eventDetails.leaderboardImage}
                      alt="Leaderboard"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>

                {/* Event Details Section */}
                <div style={{ marginLeft: "40px" }}>
                  <h3>Event Title: {eventDetails.name}</h3>
                  <h4>Event Code: {eventDetails._id}</h4>
                  <p>
                    {eventDetails.startDate} to {eventDetails.endDate}
                  </p>
                  <p>
                    From {eventDetails.startTime} to {eventDetails.endTime}
                  </p>
                  <button
                    className={`btn ${
                      eventDetails.eventStatus === "Live"
                        ? "btn-success"
                        : eventDetails.eventStatus === "Coming Soon"
                        ? "btn-warning"
                        : "btn-danger"
                    }`}
                  >
                    {eventDetails.eventStatus}
                  </button>
                </div>
              </div>

              {/* Gifts Section */}
              <div className="box-body">
                <h4 style={{ color: "black" }}>Gifts:</h4>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {eventDetails.gifts?.map((gift, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <strong style={{ color: "black" }}>{gift.title}:</strong>
                      <img
                        src={gift.giftimage}
                        alt={gift.title}
                        width="40"
                        style={{ borderRadius: "5px" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              {/* Header with Tab Buttons */}
              <div className="box-header ">
                <div
                  className="tab-content"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0",
                    width: "100%",
                  }}
                >
                  <button
                    className={`tab-button ${
                      activeTab === "registered" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("registered")}
                    style={{
                      backgroundColor:
                        activeTab === "registered" ? "#007bff" : "#f1f1f1",
                      color: activeTab === "registered" ? "#fff" : "#333",
                      padding: "10px 20px",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      marginRight: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Registered Users
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "ranking" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("ranking")}
                    style={{
                      backgroundColor:
                        activeTab === "ranking" ? "#007bff" : "#f1f1f1",
                      color: activeTab === "ranking" ? "#fff" : "#333",
                      padding: "10px 20px",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Ranking
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "20px" }}
                    onClick={sendNotification}
                  >
                    Send Notification to Registered Users
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "20px" }}
                    onClick={settleGiftsEvent}
                  >
                    Settle Gifts
                  </button>
                </div>
              </div>

              {/* Body Content for Active Tab */}
              <div className="box-body" style={{ padding: "20px" }}>
                {activeTab === "registered" ? (
                  <RegisteredUsers
                    users={registeredUsers}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                  />
                ) : (
                  <RankingUsers
                    users={rankingUsers}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RegisteredUsers = ({ users, page, setPage, limit, setLimit }) => (
  <>
    <div className="form-group col-xs-4">
      <label htmlFor="limit">Results per page:</label>
      <select
        id="limit"
        value={limit}
        onChange={(e) => {
          setPage(1);
          setLimit(Number(e.target.value));
        }}
        className="form-control"
      >
        <option value={10}>10</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Sr.</th>
          <th>Picture</th>
          <th>Username</th>
          <th>Registered Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {users?.map((user, i) => (
          <tr key={user._id}>
            <td>{(page - 1) * limit + i + 1}</td>
            <td>
              <img
                src={`${process.env.REACT_APP_BASEURL1}${user.userId?.picture}`}
                alt="User"
                width="50"
              />
            </td>
            <td>{user.userId?.username}</td>
            <td>{new Date(user.createdAt).toLocaleString()}</td>
            <td>
              <button onClick={() => unregisterEvent(user._id)}>
                Unregister
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination page={page} setPage={setPage} />
  </>
);

const RankingUsers = ({ users, page, setPage, limit, setLimit }) => (
  <>
    <div className="form-group col-xs-4">
      <label htmlFor="limit">Results per page:</label>
      <select
        id="limit"
        value={limit}
        onChange={(e) => {
          setPage(1);
          setLimit(Number(e.target.value));
        }}
        className="form-control"
      >
        <option value={10}>10</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>

    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Picture</th>
          <th>Username</th>
          <th>Gifts</th>
          <th>Free</th>
          <th>TopSender</th>
        </tr>
      </thead>
      <tbody>
        {users?.map((user, i) => (
          <tr key={user._id}>
            <td>{i + 1}</td>
            <td>
              <img
                src={`${
                  user?.registeredUser?.picture
                    ? user?.registeredUser?.picture
                    : "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                }`}
                alt={user?.registeredUser?.username}
                // width="50"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "3rem",
                }}
              />
            </td>
            <td>{user?.registeredUser?.username}</td>
            <td>
              {user?.gifts?.map((gift, index) =>
                gift?.userGifts.map((g, i) => (
                  <>
                    <div>
                      <strong>{g?.title}</strong>- {g?.count}
                    </div>
                  </>
                ))
              )}
            </td>
            <td>
              {user?.gifts?.map((gift) => (
                <span>{gift.free}</span>
              ))}
            </td>
            <td>{user?.topSenders?.username}</td>
          </tr>
        ))}
      </tbody>
    </table>

    <Pagination page={page} setPage={setPage} />
  </>
);

const Pagination = ({ page, setPage }) => (
  <ul className="pagination">
    <li>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
      >
        Previous
      </span>
    </li>
    <li>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setPage((prev) => prev + 1)}
      >
        Next
      </span>
    </li>
  </ul>
);

export default ViewEvent;
