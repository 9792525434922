import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import axios from 'axios';


const FamilyPKBattle = () => {
  const componentRef = useRef(null);
  const [leftUsername, setLeftUsername] = useState('');
  const [rightUsername, setRightUsername] = useState('');
  const [leftImage, setLeftImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [battleDate, setBattleDate] = useState('');
  const [battleTime, setBattleTime] = useState('');
  const [leftFamilyName, setLeftFamilyName] = useState('');
  const [rightFamilyName, setRightFamilyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const formattedHours = ((+hours % 12) || 12).toString();
    const ampm = +hours >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const handleDownload = async() => {
    setLoading(true)
    const leftFamily = await fetchFamilyName(leftUsername);
    const rightFamily = await fetchFamilyName(rightUsername);

    if (!leftFamily || !rightFamily) {
      setErrorMessage('Please check usernames, family not found for one or both users.');
      setLoading(false);
      return;
    }

    setLeftFamilyName(leftFamily);
    setRightFamilyName(rightFamily);
    setErrorMessage('');
    if (componentRef.current) {
      html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'Family_PK_Battle_Overlay.png';
        link.click();
      });
    }
    setLoading(false)
  };
  const fetchFamilyName = async (username) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL1}/admin/familyImage/getForBanner?username=${username}`);
      if (response.data.success && response.data.details.length > 0) {
        return response.data.details[0].family?.name || null;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching family name:', error);
      return null;
    }
  };
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Create Family PK Battle Banner</h2>

      {/* Input Form */}
      <form className="mb-4">
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Left Username</label>
            <input
              type="text"
              className="form-control"
              value={leftUsername}
              onChange={(e) => setLeftUsername(e.target.value)}
              placeholder="Enter left username"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Right Username</label>
            <input
              type="text"
              className="form-control"
              value={rightUsername}
              onChange={(e) => setRightUsername(e.target.value)}
              placeholder="Enter right username"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Left Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, setLeftImage)}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Right Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, setRightImage)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Battle Date</label>
            <input
              type="date"
              className="form-control"
              value={battleDate}
              onChange={(e) => setBattleDate(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Battle Time</label>
            <input
              type="time"
              className="form-control"
              value={battleTime}
              onChange={(e) => setBattleTime(e.target.value)}
            />
          </div>
        </div>
      </form>
      {errorMessage && (
        <div className="alert alert-danger text-center" role="alert">
          {errorMessage}
        </div>
      )}

      {/* Overlay Component */}
      <div
        ref={componentRef}
        style={{
          position: 'relative',
          width: '600px',
          height: '600px',
          margin: '0 auto',
          backgroundColor: '#333',
        }}
      >
        <img
          src="/pkbattle.jpg"
          alt="Background"
          style={{ width: '100%', height: '100%' }}
        />

        {/* Left User Image */}
        {leftImage && (
          <div
            style={{
              position: 'absolute',
              top: '150px',
              left: '10px',
              width: '190px',
              height: '230px',
              padding: '5px',
              backgroundColor: '#FFD700',
              borderRadius: '15px',
              transform: 'rotate(-6deg)',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            }}
          >
            <img
              src={leftImage}
              alt="Left User"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '10px',
              }}
            />
          </div>
        )}

        {/* Right User Image */}
        {rightImage && (
          <div
            style={{
              position: 'absolute',
              top: '150px',
              right: '10px',
              width: '190px',
              height: '230px',
              padding: '5px',
              backgroundColor: '#FFD700',
              borderRadius: '15px',
              transform: 'rotate(6deg)',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            }}
          >
            <img
              src={rightImage}
              alt="Right User"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '10px',
              }}
            />
          </div>
        )}

        {/* Left Username */}
        <div
          style={{
            position: 'absolute',
            top: '400px',
            left: '100px',
            color: '#FFF',
            fontWeight: 'bold',
            fontSize: '20px',
            transform: 'rotate(-6deg)',
          }}
        >
          {leftUsername || 'Left User'}
        </div>

        {/* Right Username */}
        <div
          style={{
            position: 'absolute',
            top: '400px',
            right: '100px',
            color: '#FFF',
            fontWeight: 'bold',
            fontSize: '20px',
            transform: 'rotate(6deg)',
          }}
        >
          {rightUsername || 'Right User'}
        </div>

        {/* Date and Time */}
        <div
          style={{
            position: 'absolute',
            bottom: '60px',
            width: '100%',
            textAlign: 'center',
            fontSize: '22px',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          <svg width="100%" height="80">
            <defs>
              <path
                id="curvePath"
                d="M 50,50 C 200,10 400,10 550,50"
              />
            </defs>
            <text fontSize="22" fontWeight="bold" fill="white">
              <textPath href="#curvePath" startOffset="50%" textAnchor="middle">
                {battleTime && battleDate &&
                  `Time: ${formatTime(battleTime)}, Date: ${new Date(battleDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' })}`}
              </textPath>
            </text>
          </svg>
        </div>

      </div>

      {/* Download Button */}
      <button
        onClick={handleDownload}
        disabled={loading}
        className="btn btn-primary mt-3"
        style={{ display: 'block', margin: '0 auto' }}
      >
       {loading?"Downloading": "Download Image"}
      </button>
    </div>
  );
};

export default FamilyPKBattle;
