import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { toast } from "react-toastify";
import axios from "axios";
import _ from "lodash";
var BaseUrl = process.env.REACT_APP_BASEURL3;

const AgencyListReq = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [search, setSearch] = useState("");
  // Fetch hosts by status and page
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      if(value!="")
        searhUser(value);
    else fetchHostsByStatus(props.status,currentPage)
    }, 1000),
    []
  );
  let bddata = JSON.parse(sessionStorage.getItem("bd"))
  let bd = "";
  if(bddata) bd = bddata.uniqueId
  const fetchHostsByStatus = async (status, page) => {
    try { 
      setCurrentUsers([])
      let data = await fetch(
        `${process.env.REACT_APP_BASEURL1}/admin/agencyPanel/getAgencyReqList?status=${status}&bd=${bd}&page=${page}&limit=${entriesPerPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let res = await data.json();
      setTotalPages(res.total);
      setCurrentUsers(res.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const aprrovedHost = async(id,status)=>{
    try {
        const {data} = await axios.post(`${process.env.REACT_APP_BASEURL1}/admin/agencyPanel/manageReq`,({status,id}));
        toast.success("Agency Request Updated");
        fetchHostsByStatus(props.status,currentPage);

    } catch (error) {
        
    }
  }
  // useEffect(() => {
  //   fetchHostsByStatus(props.status, currentPage);
  // }, [props.status]);
  useEffect(() => {
    fetchHostsByStatus(props?.status, currentPage);
  }, [props?.status,entriesPerPage,currentPage]);

  // Logic to paginate the users

  // Change page
  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetchHostsByStatus(props?.status, pageNumber);
  };

 

  const searhUser = async (value) => {
    try {
      setCurrentUsers([])
      // setSearchData(filterValue);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/admin/agencyPanel/getAgencyReqListSearch?status=${props.status}&bd=${bd}&search=${value}`
      );
      // setSearchData(data.details);
      setCurrentUsers(data.details);
      setTotalPages(data.total);

    } catch (error) {
      console.log(error.message);
    }
  };
 
  return (
    <>
      <section className="content-header">
        <h2>Agency Admin Req for Agency</h2>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>{
                        setCurrentUsers([])
                        setEntriesPerPage(parseInt(e.target.value))}
                      }
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div
                    class="col-md-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      class="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                          onChange={(e) => {
                            handleSearchChange(e.target.value);
                          }}
                          placeholder="Searh User"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>AgencyAdmin Code</th>
                      <th>AgencyAdmin Name</th>
                      <th>Agency Code</th>
                      <th>Agency name</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {currentUsers.length==0 ?<>Loading...</>: Array.isArray(currentUsers) &&
                        currentUsers.map((item, index) => {
                          let i = index ;
                          return (
                            <tr>
                              <td>{((totalPages - (currentPage-1)*entriesPerPage) - i)}</td>
                              <td>{new Date(item?.createdAt).toLocaleString()}</td>
                              <td>{item?.admin}</td>
                              <td>{item?.adminName}</td>
                              <td>{item?.uniqueId}</td>
                              <td>{item?.name}</td>
                              <td>{item?.number}</td>
                              <td>
                                {item.status == "0" ? (
                                  <td>
                                    <span className="label label-info">
                                      Pending
                                    </span>
                                  </td>
                                ) : item.status == 1 ? (
                                  <td>
                                    <span className="label label-success">
                                      Approved
                                    </span>
                                  </td>
                                ) : (
                                  <td>
                                    <span className="label label-danger">
                                      Rejected
                                    </span>
                                  </td>
                                )}
                              </td>
                              <td>
                                <ul
                                  className="admin-action btn btn-default"
                                  style={{
                                    backgroundColor: "#f4f4f4",
                                    color: "#fff !important",
                                  }}
                                >
                                  <li className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      style={{ color: "black" }}
                                      data-toggle="dropdown"
                                      href="#"
                                      aria-expanded="false"
                                    >
                                      Action <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                      {item?.status == 0 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                                aprrovedHost(item._id,"1");
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                                aprrovedHost(item._id,"2");
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                        </>
                                      ) : item?.status == 1 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              aprrovedHost(item._id,"2");
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              aprrovedHost(
                                                item._id,
                                                "1",
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}

                    {/* Repeat this row structure for each data item */}
                  </tbody>
                </table>
                {/* Pagination links */}

                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{totalPages}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencyListReq;
